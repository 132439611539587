.owl {
  position: relative;
}

.owl-carousel.slide-one .item {
  width: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  display: flex;
  flex-direction: column;
  justify-content: center;

  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: .8;
    z-index: 1;
    background-color: #31446e;
  }
}

.owl__inner {
  padding-left: 12.8rem;
  position: relative;
  z-index: 2;

  color: $white;
}

.owl__suptitle {
  font-weight: 500;
  font-size: 3.4rem;
}

.owl__title {
  margin-bottom: 2rem;
  font-weight: 700;
  font-size: 8.1rem;
}

.owl__text {
  margin-left: 1.4rem;
  max-width: 56rem;
  margin-bottom: 3rem;
  font-weight: 500;
  font-size: 1.6rem;
}

.owl__link {
  margin-left: 2.7rem;
  padding-bottom: .9rem;
  font-size: 1.9rem;
  font-weight: 900;

  position: relative;

  &:after {
    content: '';
    display: block;
    width: 40%;
    height: 3px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #bf334d;
    transition: width .3s ease-out;
  }

  &:hover:after {
    width: 100%;
  }

  span {
    margin-left: 1rem;
    font-family: 'Roboto', sans-serif;
    font-size: 1.8rem;
    font-weight: 400;
  }
}

.owl-carousel.slide-one .owl-nav {
  position: absolute;
  bottom: 4.6%;
  left: 6.25%;
  z-index: 1;
}

.owl-carousel.slide-one .owl-nav button.owl-prev,
.owl-carousel.slide-one .owl-nav button.owl-next {
  background-color: transparent;
  border: 2px solid $white;

  &:focus {
    outline: none;
  }

  span {
    font-family: 'Roboto', sans-serif;
    color: $white;
    padding: 0 1.8rem;
    font-size: 3.5rem;
    font-weight: 400;
  }
}

.owl-carousel.slide-one .owl-nav button.owl-prev {
  margin-right: 1.5rem;
}

.slide-one .owl-dots {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.slide-one .owl-dot {
  padding: 2px 0!important;
  margin-bottom: 3rem;
  background-color: $white!important;
  width: 6rem;
  height: 2px;
  position: relative;
  z-index: 1;

  &:last-of-type {
    margin-bottom: 0;
  }

  &:focus {
    outline: none;
  }

  span {
    position: absolute;
    left: -2rem;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;

    font-family: 'Roboto', sans-serif;
    color: $white;
    font-size: 1.9rem;
    font-weight: 400;
  }
}

.slide-one .owl-dot.active {
  width: 10rem;
}

.owl__scroll-down {
  position: absolute;
  z-index: 1;
  bottom: 3%;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  flex-direction: column;
  align-items: center;
}

.owl__scroll-down__text {
  margin-top: .8rem;
  color: $white;
  font-size: 1.2rem;
  font-weight: 500;
}

@media (max-width: 1299px) {
  .owl__suptitle {
    font-size: 3rem;
  }

  .owl__title {
    font-size: 7.5rem;
  }

  .owl__inner {
    padding-left: 10rem;
  }
}


@media (max-width: 1199px) {
  .owl__title {
    font-size: 6rem;
  }

  .owl__inner {
    padding-left: 5rem;
  }
}

@media (max-width: 991px) {
  .owl__suptitle {
    font-size: 2.8rem;
  }

  .owl__title {
    font-size: 5.5rem;
  }

  .owl__inner {
    padding-left: 0;
  }

  .owl-carousel.slide-one .item {
    min-height: 80rem;
  }
}

@media (max-width: 767px) {
  .owl__text {
    margin-left: 0;
    text-align: center;
  }

  .owl__suptitle {
    font-size: 2.5rem;
  }

  .owl__title {
    font-size: 5rem;
  }

  .owl__link {
    font-size: 1.6rem;

    span {
      font-size: 1.4rem;
      margin-left: .5rem;
    }
  }

  .owl__scroll-down {
    display: none;
  }
}

@media (max-width: 639px) {
  .owl__suptitle {
    font-size: 2.2rem;
  }

  .owl__title {
    font-size: 4.5rem;
  }

  .owl__link {
    margin-left: 0;
  }
}

@media (max-width: 575px) {
  .owl__suptitle {
    font-size: 2rem;
  }

  .owl__title {
    font-size: 4rem;
  }
}