.product {
  margin-bottom: 9rem;
  padding-left: 7rem;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 10rem;
  align-items: flex-start;
}

.product__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.product__title {
  margin-bottom: 2rem;
  font-size: 3rem;
  font-weight: 700;
  color: $blue;

  padding: 0 0 .5rem 0;
  position: relative;

  &:after {
    content: '';
    width: 50%;
    height: 6px;
    background-color: #bf334d;

    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
}

.product__price {
  margin-bottom: 3rem;
  background-color: $blue;
  color: $white;
  font-size: 2.5rem;
  font-weight: 700;
  padding: 1rem 2rem;
}

.product__long {
  font-size: 2rem;

  span {
    font-weight: 700;
    margin-left: .5rem;
  }
}

.product__existence {
  margin-bottom: 2.5rem;
  font-size: 2rem;

  span {
    font-weight: 700;
    color: #39ba89;
    margin-left: .5rem;
  }
}

.product__price-per-quantity {
  margin-bottom: 3.5rem;
  font-size: 1.9rem;
  font-weight: 900;
  padding: 0 0 .2rem 0;
  position: relative;

  &:after {
    content: '';
    width: 50%;
    height: 2px;
    background-color: #bf334d;

    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
}

.product__standard {
  margin-bottom: 4.5rem;
  font-size: 1.9rem;
  font-weight: 900;
}

.product__size {
  margin-bottom: 6rem;
  display: flex;
}

.product__size-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5rem;
  height: 5rem;
  background-color: $blue;
  cursor: pointer;

  color: $white;
  font-weight: 700;
  transition: background-color .2s linear;

  &:hover {
    background-color: darken($blue, 5%);
  }

  &.active {
    background-color: #eaeaea;
    color: $blue;
  }
}

.product__size-label + .product__size-label {
  margin-left: 1rem;
}

.product__action {
  display: flex;
}

.product__counter {
  margin-right: 3rem;
  display: flex;
  align-items: center;
}

.product__counter-minus,
.product__counter-plus {
  width: 3rem;
  height: 6rem;
  background-color: $blue;
  padding: 0;
  border: 0;
  cursor: pointer;
  position: relative;
  transition: background-color .2s linear;

  &:hover {
    background-color: darken($blue, 5%);
  }

  &:before,
  &:after {
    content: '';
    display: block;
    background-color: $white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}

.product__counter-plus:before,
.product__counter-minus:before {
  width: 10px;
  height: 2px;
}

.product__counter-plus:after {
  width: 2px;
  height: 10px;
}

.product__counter-input {
  padding: 0 1rem;
  margin: 0 1rem;
  width: 7.5rem;
  height: 6rem;
  border: 1px solid #707070;
  color: #707070;
  font-size: 2rem;
  outline: none;
}

.product__action-buttons {
  display: flex;
  align-items: center;
}

.product__btn-buy {
  height: 6rem;
  margin-right: 3rem;
  background-color: $blue;
  padding: 0 5rem;

  display: flex;
  align-items: center;

  font-size: 2rem;
  font-weight: 400;
  color: $white;

  transition: background-color .2s linear;

  &:hover {
    background-color: darken($blue, 5%);
  }

  svg {
    display: block;
    margin-left: .5rem;
    margin-top: .2rem;
  }
}

.product__btn-question {
  height: 6rem;
  padding: 0 1.5rem;
  background-color: $white;
  border: 2px solid $blue;

  font-size: 2rem;
  font-weight: 400;
  color: $blue;
}

@media (max-width: 1599px) {
  .product {
    padding-left: 3rem;
    grid-column-gap: 5rem;
  }
}

@media (max-width: 1399px) {
  .product {
    grid-column-gap: 3rem;
  }

  .product__title {
    font-size: 2.5rem;
  }

  .product__price {
    font-size: 2rem;
  }

  .product__action {
    display: block;
  }

  .product__counter {
    margin-bottom: 3rem;
    margin-right: 0;
  }
}

@media (max-width: 1299px) {
  .product__long,
  .product__existence {
    font-size: 1.9rem;
  }

  .product__price-per-quantity,
  .product__standard {
    font-size: 1.8rem;
  }

  .product__title {

    &:after {
      height: 3px;
    }
  }

  .product {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 1199px) {
  .product {
    padding-left: 0;
  }
}

@media (max-width: 991px) {
  .product {
    grid-template-columns: 1fr;
    grid-row-gap: 3rem;
    padding-left: 2rem;
  }

  .product__content {
    padding-left: 2rem;
  }
}

@media (max-width: 767px) {
  .product {
    padding-left: 0;
  }

  .product__content {
    padding-left: 3rem;
  }

  .product__price {
    font-size: 1.8rem;
  }

  .product__price-per-quantity, .product__standard {
    font-size: 1.6rem;
  }

  .product__size-label {
    font-size: 1.5rem;
  }

  .product__counter-minus,
  .product__counter-plus {
    height: 5rem;
  }

  .product__counter-input {
    width: 6rem;
    height: 5rem;
    font-size: 1.8rem;
  }

  .product__size {
    margin-bottom: 4rem;
  }

  .product__btn-buy, .product__btn-question {
    height: 5rem;
    font-size: 1.8rem;
  }

  .product__btn-buy svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

@media (max-width: 575px) {
  .product__content {
    padding-left: 0;
  }

  .product__title {
    font-size: 2.2rem;
  }

  .product__size-label {
    font-size: 1.4rem;
  }
}

@media (max-width: 399px) {
  .product__action-buttons {
    display: block;
  }

  .product__btn-buy {
    margin-right: 0;
    margin-bottom: 2rem;
    padding: 0 4rem;
  }

  .product__btn-question {
    padding: 0 1.85rem;
  }
}

@media (max-width: 349px) {
  .product__size-label + .product__size-label {
    margin-left: .9rem;
  }
}