.slide-two .owl-dots {
  position: absolute;
  bottom: -5rem;
  left: 50%;
  transform: translateX(-50%);
}

.slide-two .owl-dot {
  background-color: rgba(116, 138, 213, .54)!important;
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  margin: 0 .9rem;
  outline: none;
}

.slide-two .owl-dot.active {
  background-color: #193db9!important;
}

@media (max-width: 1299px) {
  .slide-two .owl-dot {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 .9rem;
  }

  .slide-two .owl-dots {
    bottom: -3rem;
  }
}