.copyright {
  background-color: #0c1e5c;
  color: $white;
  height: 3rem;
  display: flex;
  align-items: center;
}

.copyright__inner {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.copyright__text, .copyright__link {
  font-size: 1.4rem;
  font-weight: 400;
}

@media (max-width: 575px) {
  .copyright {
    margin-bottom: 4.4rem;
  }
}

@media (max-width: 529px) {
  .copyright {
    height: auto;
    display: block;
  }

  .copyright__inner {
    padding: 1rem 0;
    display: block;
  }

  .copyright__text {
    margin-bottom: .5rem;
  }
}