.our-service {
  margin: 9rem 0 8rem;
}

.our-service__intro {
  margin-bottom: 7rem;
  min-height: 47rem;
  background: url('../../img/our-service-bg.jpg') no-repeat center;
  background-size: cover;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: .78;
    z-index: 0;
    background-color: #23304d;
  }
}

.our-service__btn {
  position: absolute;
  right: 2%;
  bottom: 5%;
  z-index: 1;

  color: $white;
  border: 2px solid $white;
  font-size: 1.9rem;
  font-weight: 700;
  padding: 15px 53px 19px 55px;
}

@media (max-width: 1199px) {
  .our-service__btn {
    right: 3%;
  }
}

@media (max-width: 991px) {
  .our-service__btn {
    font-weight: 700;
    padding: 15px 40px;
  }
}

@media (max-width: 767px) {

  .our-service__btn {
    font-size: 1.8rem;
  }
}

@media (max-width: 575px) {
  .our-service__btn {
    padding: 10px 30px;
  }
}