.breadcrumb {
  margin: 18.5rem 0 20rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  list-style: none;

  &--low-margin {
    margin: 18.5rem 0 6rem;
  }

  @media (max-width: 1899px) {
    margin: 18.5rem 0 20rem 5rem;

    &--low-margin {
      margin: 18.5rem 0 6rem 5rem;
    }
  }

  @media (max-width: 1199px) {
    margin: 18.5rem 0 20rem 3rem;

    &--low-margin {
      margin: 18.5rem 0 6rem 3rem;
    }
  }

  @media (max-width: 767px) {
    margin: 16rem 0 14rem 3rem;

    &--low-margin {
      margin: 16rem 0 6rem 3rem;
    }
  }

  @media (max-width: 575px) {
    margin: 16rem 0 8rem;

    &--low-margin {
      margin: 16rem 0 6rem;
    }
  }
}

.breadcrumb-item {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;

  font-family: 'Roboto', sans-serif;
  font-size: 1.4rem;
  color: #6a6a6a;

  a {
    color: $blue;
  }
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  margin-right: 1rem;
  content: '';
  width: 12px;
  height: 6px;
  background: url('../img/breadcrumb-arrow.svg') no-repeat center;
  background-size: cover;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 1rem;
}