.extra {
  margin: 9rem 0 12rem;
  display: grid;
  grid-template-columns: repeat(4, 34.6rem);
  grid-column-gap: 3rem;
  justify-content: center;
}

@media (max-width: 1599px) {
  .extra {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 1199px) {
  .extra {
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 3rem;
  }
}

@media (max-width: 991px) {
  .extra {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 575px) {
  .extra {
    grid-template-columns: 1fr;
  }
}