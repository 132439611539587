*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  font-size: 62.5%; /* 62.5% от 16px(дефолт знач шрифта в браузере) = 10px */
}

body {
  margin: 0;

  font-family: 'Futura PT', sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.3;
  color: $blue-dark;
}

body.lock {
  overflow: hidden;
}

@media (max-width: 767px) {
  body.lock-mobile {
    overflow: hidden;
  }
}

.main {
  overflow: hidden;
}

.container {
  width: 100%;
  max-width: 1740px;
  margin: 0 auto;
  padding: 0 2rem;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
}

.img {
  display: block;
  max-width: 100%;
  height: auto;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

p {
  margin: 0;
}

ul, ol {
  padding: 0;
  margin: 0;
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

.map iframe {
  display: block;
  border: none;
  width: 100%;
  height: 100%;
}

.map {
  height: 100%;
  background: url('../img/map-bg.jpg') no-repeat center;
  background-size: cover;

  width: 50%;

  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;

  @media (max-width: 991px) {
    right: auto;
    top: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;

    iframe {
      height: 40rem;
    }
  }
}

.text-general {
  font-size: 1.9rem;

  p {
    margin-bottom: 2rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  @media (max-width: 767px) {
    font-size: 1.8rem;
  }

  @media (max-width: 575px) {
    font-size: 1.7rem;
  }
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
}