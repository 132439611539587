.btn {
  padding: 0;
  display: inline-block;
  vertical-align: middle;

  font-family: 'Futura PT', sans-serif;

  background: none;
  border: 0;
  text-decoration: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.btn--classic {
  font-size: 1.9rem;
  color: $white;
  background-color: $blue;
  transition: background-color .2s linear;

  padding: 1.4rem 5rem;

  &:hover {
    background-color: darken($blue, 5%);
  }
}

@media (max-width: 1199px) {
  .btn--classic {
    font-size: 1.6rem;

    padding: 1.4rem 4rem;
  }
}