.nav-toggle {
  display: none;
  width: 24px;
  padding: 10px 0;
  font-size: 0;
  color: transparent;
  border: 0;
  background: 0 0;
  cursor: pointer;
  outline: 0;
}

.nav-toggle__item {
  display: block;
  width: 100%;
  height: 2px;
  background-color: $white;

  position: relative;
  z-index: 4;

  &:before,
  &:after {
    content: "";
    width: 100%;
    height: 2px;
    background-color: $white;

    position: absolute;
    left: 0;
    z-index: 1;

    transition: transform .2s linear;
  }

  &:before {
    top: -8px;
  }

  &:after {
    top: 8px;
  }
}

.nav-toggle__item--blue {
  background-color: $blue;

  &:before,
  &:after {
    background-color: $blue;
  }
}

.nav-toggle.active .nav-toggle__item {
  background: none;
}

.nav-toggle.active .nav-toggle__item:before {
  transform-origin: left top;
  transform: rotate(45deg);
  background-color: $blue;
}

.nav-toggle.active .nav-toggle__item:after {
  transform-origin: left bottom;
  transform: rotate(-45deg);
  background-color: $blue;
}

@media (max-width: 1159px) {
  .nav-toggle {
    display: block;
  }
}

@media (max-width: 575px) {
  .nav-toggle__item {
    background-color: $blue;

    &:before,
    &:after {
      background-color: $blue;
    }
  }
}