.news-page {
  margin: 9rem 0 11rem;
}

.news-page__intro {
  margin-bottom: 7rem;
  min-height: 47rem;
  background: url('../../img/news-page-bg.jpg') no-repeat center;
  background-size: cover;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: .78;
    z-index: 0;
    background-color: #23304d;
  }
}

.news-page__intro time {
  padding: 11px 22px 11px 19px;
  background-color: $blue;
  color: $white;

  display: flex;
  align-items: center;

  position: absolute;
  bottom: 5%;
  left: 0;
  z-index: 1;

  svg {
    margin-top: -3px;
    margin-right: 1.2rem;
  }
}

.news-page__text {
  margin-bottom: 4rem;
}

.news-page__share {
  height: 8.6rem;
  padding: 0 7rem 0 4rem;
  background-color: $white;
  box-shadow: 0 3px 60px rgba(0, 0, 0, .16);

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.news-page__share-title {
  font-size: 2rem;
  color: $blue;
  font-weight: 900;
}

@media (max-width: 991px) {

  .news-page__share {
    padding: 0 4rem 0 3rem;
  }
}

@media (max-width: 767px) {

  .news-page__share-title {
    margin-bottom: 3rem;
  }

  .news-page__share {
    height: auto;
    padding: 3rem 2rem;
    flex-direction: column;
    align-items: flex-start;

    a {
      margin-bottom: 1rem;
    }
  }
}