.popup-fade {
  display: none;
}

.popup-close {
  display: none;
}

@media (max-width: 767px) {

  .popup-fade:before {
    content: '';
    background-color: $black;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    z-index: 9;
  }

  .popup {
    display: flex;
    justify-content: center;
    width: 30rem;

    position: fixed;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);

    background-color: $white;
    padding: 7rem 2rem 5rem;
    z-index: 99;
    opacity: 1;

    &--search {
      padding: 8rem 4rem 6rem;
    }
  }

  .popup-close {
    display: block;
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;

    width: 2rem;
    height: 2rem;

    background: none;
    border: none;
    cursor: pointer;
    padding: 0;

    &:before,
    &:after {
      content: ' ';
      height: 2rem;
      width: 2px;
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -1px;
      background-color: $blue;
    }

    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}