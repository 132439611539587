.card {
  display: flex;
  flex-direction: column;
  height: 100%;

  max-width: 34.6rem;
  margin: 0 auto;
  background-color: #f7f7f7;
}

.card__header {
  background-color: #161c2d;
  padding-bottom: 81%;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    opacity: .3;
    z-index: 0;
    background-color: #23304d;
  }
}

.card__header img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.card__header-price {
  background-color: $blue;
  color: $white;
  font-size: 2rem;
  font-weight: 700;
  padding: 1rem 2rem;

  position: absolute;
  left: 0;
  bottom: 10%;
  z-index: 1;
}

.card__content {
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 4rem 2.4rem 4rem 1.4rem;
}

.card__title {
  font-size: 2rem;
  color: #2c2c2c;
  font-weight: 700;
}

.card__btn {
  margin: 1rem 0 0 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 3.5rem;
  height: 3.5rem;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
}

@media (max-width: 1399px) {
  .card__title {
    font-size: 1.8rem;
  }

  .card__btn {
    margin: 1rem 0 0 2rem;
    width: 3rem;
    height: 3rem;

    svg {
      width: 3rem;
      height: 3rem;
    }
  }

  .card__header-price {
    font-size: 1.8rem;
    padding: 1rem 1.8rem;
  }
}

@media (max-width: 1299px) {
  .card__content {
    padding: 3.5rem 1.5rem;
  }

  .card__btn {
    margin: 1rem 0 0 1.5rem;
    width: 2.5rem;
    height: 2.5rem;

    svg {
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  .card__header-price {
    padding: 1rem 1.5rem;
  }
}

@media (max-width: 991px) {
  .card__content {
    padding: 3rem 1.5rem;
  }

  .card__btn {
    margin: 1rem 0 0 1.5rem;
    width: 2.2rem;
    height: 2.2rem;

    svg {
      width: 2.2rem;
      height: 2.2rem;
    }
  }

  .card__title {
    font-size: 1.6rem;
  }

  .card__header-price {
    padding: 1rem 1.5rem;
    font-size: 1.6rem;
  }
}

@media (max-width: 767px) {
  .card__btn {
    margin: 1rem 0 0 3rem;
    width: 2.2rem;
    height: 2.2rem;

    svg {
      width: 2.2rem;
      height: 2.2rem;
    }
  }
}

@media (max-width: 575px) {
  .card__content {
    padding: 3.5rem 2rem;
  }
}