//catalog-dropdown

.catalog-dropdown {
  display: none;

  padding: 2rem;
  background-color: $white;
  box-shadow: 0 7px 6px rgba(0, 0, 0, .16);

  position: absolute;
  left: 0;
  top: 140%;
  z-index: 2;

  svg {
    margin-right: 1.5rem;
    width: 2.5rem;
  }
}

.catalog-dropdown__link {
  padding: .5rem 0;
  display: flex;
  align-items: center;

  white-space: nowrap;
  font-family: 'Roboto', sans-serif;
  font-size: 2rem;
  color: #3e3e3e;

  &:hover {
    color: $blue;
  }
}

.catalog-dropdown__icon-rotate {
  transform: rotate(45deg);
}

//tel-dropdown

.tel-dropdown {
  width: 20rem;
  padding: 1.5rem 1rem;
  background-color: $white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16);

  position: absolute;
  left: -1rem;
  top: 190%;
  z-index: 3;

  svg {
    fill: $blue;
  }
}

.tel-dropdown__item {
  margin-bottom: 1rem;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.tel-dropdown__link {
  display: flex;
  align-items: center;
  font-weight: 700;
  color: $blue;
}

//search-dropdown

.search-dropdown {
  position: absolute;
  right: 0;
  top: 190%;
  z-index: 2;

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: $white;
  }
}

.search-dropdown--blue {

  &:after {
    background: $blue;
  }
}

.search-dropdown__btn {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.search-dropdown__input {
  width: 32rem;
  font-family: 'Futura PT', sans-serif;
  font-style: normal;
  font-size: 1.9rem;
  font-weight: 400;
  color: $white;

  padding: 0 3rem 1rem 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &::placeholder {
    color: $white;
  }

  &--blue {
    color: $blue;

    &::placeholder {
      color: $blue;
    }
  }
}

@media (max-width: 1599px) {
  .tel-dropdown {
    right: 0;
    left: auto;
  }

  .search-dropdown__input {
    width: 25rem;
  }
}

@media (max-width: 1199px) {
  .catalog-dropdown__link {
    font-size: 1.8rem;
  }
}

@media (max-width: 991px) {
  .catalog-dropdown__link {
    font-size: 1.6rem;
  }
}

@media (max-width: 767px) {
  .tel-dropdown {
    width: auto;
    box-shadow: none;
    padding: 0;
    position: static;
  }

  .search-dropdown {
    position: relative;
    width: 100%;

    &:after {
      background: $blue;
    }
  }

  .search-dropdown__input {
    padding: 0 3rem .5rem 0;
    color: $blue;
    width: 100%;

    &::placeholder {
      color: $blue;
    }
  }

  .search-dropdown__btn {
    width: 2rem;
    height: 2rem;
    top: 10%;
    right: 1%;

    svg {
      fill: $blue;
      width: 2rem;
      height: 2rem;
    }
  }
}

@media (max-width: 575px) {
  .catalog-dropdown {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    position: static;
  }
}