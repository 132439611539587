.thanks__container {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
}

.thanks__title {
  margin-bottom: 4rem;
  font-size: 3.2rem;
  color: $blue;
  font-weight: 700;
  text-align: center;
}

.thanks__text {
  margin-bottom: 4.5rem;
  text-align: center;
  font-size: 1.9rem;
  color: $blue;
  font-weight: 400;
}