.section--about {
  padding-top: 30rem;
  margin-bottom: 17rem;
  padding-left: 5rem;

  &-another {
    padding-top: 0;
    padding-left: 0;
  }
}

.section--works {
  margin-bottom: 15rem;
  position: relative;

  &:before {
    @include circle;
    bottom: 0;
    left: 0;
  }

  &:after {
    @include rectangle;
    top: 26rem;
    right: 0;
  }
}

.section--services {
  margin-bottom: 11rem;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 660px;
    height: 660px;

    background: url('../../img/services-circle-big.svg') center no-repeat;
    background-size: contain;

    position: absolute;
    bottom: -6%;
    left: -16%;
    z-index: -2;
  }

  &:after {
    content: '';
    display: block;
    width: 321px;
    height: 307px;

    background: url('../../img/services-decor-grid.png') center no-repeat;
    background-size: contain;

    position: absolute;
    bottom: -13%;
    right: 0;
    z-index: -2;
  }
}

.section--recommendation {
  margin-bottom: 18rem;
}

.section--news {
  margin-bottom: 10rem;
  position: relative;

  &:before {
    @include rectangle;
    left: -5%;
    top: 0;
  }
}

.section--question {
  margin-bottom: 26rem;
  background: url('../../img/question-bg.jpg') no-repeat center;
  background-size: cover;

  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: .78;
    z-index: 0;
    background-color: #23304d;
  }
}

.section--photo-gallery {
  padding-left: 4rem;
  margin-bottom: 23.5rem;
}

.section--our-services {
  position: relative;

  &:before {
    @include circle;
    top: 30%;
    left: 0;
  }

  &:after {
    @include rectangle;
    top: 10%;
    right: 0;
  }
}

.section--our-services__text-overflow {
  margin-bottom: 8rem;
  position: relative;

  &:before {
    @include circle;
    top: 0;
    left: 0;
  }

  &:after {
    @include rectangle;
    bottom: 0;
    right: 0;
  }
}

.section--our-news {
  margin-bottom: 8.6rem;
  position: relative;

  &:before {
    @include circle;
    top: 30%;
    left: 0;
  }

  &:after {
    @include rectangle;
    top: 10%;
    right: 0;
  }
}

.section--tabs {
  position: relative;

  &:after {
    content: '';
    display: block;
    width: 321px;
    height: 307px;

    background: url('../../img/services-decor-grid.png') center no-repeat;
    background-size: contain;

    position: absolute;
    bottom: -20%;
    right: 0;
    z-index: -2;
  }
}

.section--order {
  margin-bottom: 9rem;
  position: relative;

  &:before {
    @include circle;
    bottom: 20%;
    left: 0;
  }

  &:after {
    @include rectangle;
    top: 20%;
    right: 0;
  }
}

.section--thanks {
  margin-bottom: 9rem;
  position: relative;

  &:after {
    @include rectangle;
    top: 20%;
    right: 0;
  }
}

@media (max-width: 1599px) {
  .section--works {

    &:before {
      background-position: left center;
      left: -10%;
      bottom: -5%;
    }

    &:after {
      background-position: right center;
      right: -10%;
      top: 10%;
    }
  }

  .section--services {
    &:before {
      background-size: 85%;
      bottom: -12%;
      left: -20%;
    }

    &:after {
      background-size: 80%;
      bottom: -13%;
      right: -5%;
    }
  }

  .section--news {

    &:before {
      left: -10%;
      top: 5%;
    }
  }

  .section--our-services {

    &:before {
      top: 25%;
      left: -5%;
    }

    &:after {
      top: 5%;
      right: -5%;
    }
  }

  .section--our-services__text-overflow {

    &:before {
      top: -20%;
      left: -5%;
    }

    &:after {
      bottom: -20%;
      right: -5%;
    }
  }

  .section--our-news {

    &:before {
      top: 25%;
      left: -5%;
    }

    &:after {
      top: 5%;
      right: -5%;
    }
  }

  .section--tabs {

    &:after {
      background-size: 80%;
      bottom: -20%;
      right: -5%;
    }
  }

  .section--order {

    &:before {
      bottom: 20%;
      left: -10%;
    }

    &:after {
      top: 20%;
      right: -10%;
    }
  }

  .section--thanks {

    &:after {
      top: 15%;
      right: -10%;
    }
  }
}

@media (max-width: 1499px) {
  .section--services {
    &:before {
      background-size: 80%;
      bottom: -17%;
      left: -22%;
    }
  }
}

@media (max-width: 1399px) {
  .section--news {

    &:before {
      left: -15%;
      top: 15%;
    }
  }

  .section--our-services__text-overflow {

    &:before {
      top: -45%;
      left: -15%;
    }

    &:after {
      bottom: -55%;
      right: -15%;
    }
  }

  .section--our-services {

    &:before {
      top: 20%;
      left: -15%;
    }

    &:after {
      top: 1%;
      right: -15%;
    }
  }

  .section--our-news {

    &:before {
      top: 20%;
      left: -15%;
    }

    &:after {
      top: -4%;
      right: -15%;
    }
  }

  .section--order {

    &:before {
      bottom: 10%;
      left: -15%;
    }

    &:after {
      top: 10%;
      right: -15%;
    }
  }

  .section--thanks {

    &:after {
      top: 10%;
      right: -15%;
    }
  }
}

@media (max-width: 1299px) {
  .section--services {
    &:before {
      background-size: 70%;
      bottom: -21%;
      left: -25%;
    }
  }
}

@media (max-width: 1199px) {
  .section--about {
    padding-left: 0;
  }

  .section--services {
    &:before {
      display: none;
    }

    &:after {
      background-size: 70%;
      bottom: -15%;
      right: -10%;
    }
  }

  .section--news {

    &:before {
      left: -20%;
      top: 20%;
    }
  }

  .section--photo-gallery {
    padding: 0;
  }

  .section--our-services__text-overflow {

    &:before {
      left: -20%;
    }

    &:after {
      right: -20%;
    }
  }

  .section--our-services {

    &:before {
      left: -20%;
    }

    &:after {
      right: -20%;
    }
  }

  .section--tabs {

    &:after {
      background-size: 70%;
      bottom: -20%;
      right: -10%;
    }
  }
}

@media (max-width: 991px) {
  .section--about {
    padding-top: 15rem;

    &-another {
      padding-top: 0;
      padding-left: 0;
    }
  }
}

@media (max-width: 767px) {
  .section--services:after {
    display: none;
  }

  .section--photo-gallery {
    margin-bottom: 15rem;
  }

  .section--question {
    margin-bottom: 15rem;
  }

  .section--tabs {

    &:after {
      display: none;
    }
  }
}