@mixin circle {
  content: '';
  display: block;
  width: 564px;
  height: 564px;

  background: url('../img/circle.svg') center no-repeat;
  background-size: contain;

  position: absolute;
  z-index: -2;

  @media (max-width: 1599px) {
    background-size: 80%;
  }

  @media (max-width: 1399px) {
    background-size: 60%;
  }

  @media (max-width: 1023px) {
    display: none;
  }
}

@mixin rectangle {
  content: '';
  display: block;
  width: 636px;
  height: 636px;

  background: url('../img/rectangle.svg') center no-repeat;
  background-size: contain;

  position: absolute;
  z-index: -2;

  @media (max-width: 1599px) {
    background-size: 80%;
  }

  @media (max-width: 1399px) {
    background-size: 60%;
  }

  @media (max-width: 1023px) {
    display: none;
  }
}