.callback {
  padding: 7rem 8rem 8rem;
}

.callback__title {
  margin-bottom: 5.5rem;
  font-weight: 700;
  font-size: 2.5rem;
  color: $blue;
  text-transform: uppercase;
  text-align: center;
}

.callback__form-inner {
  margin-bottom: 5.3rem;

  display: grid;
  grid-template-rows: 6.4rem 6.4rem;
  grid-row-gap: 3.4rem;
}

.callback__form-input {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-size: 1.9rem;
  font-weight: 400;
  color: $black;
  outline: 0;
  transition: border .2s linear;

  padding: 0 1.6rem;
  background: none;
  border: 1px solid #707070;
  border-radius: 5px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &::placeholder {
    color: #6a6a6a;
  }

  &:focus {
    border-color: $blue;
  }
}

.callback__btn {
  margin: 0 auto;
  display: block;
}

@media (max-width: 1299px) {
  .callback {
    padding: 5rem 7rem;
  }

  .callback__title {
    margin-bottom: 5rem;
    font-size: 2.2rem;
  }

  .callback__form-inner {
    margin-bottom: 5rem;

    grid-template-rows: 5rem 5rem;
    grid-row-gap: 3rem;
  }

  .callback__form-input {
    font-size: 1.6rem;
  }
}

@media (max-width: 639px) {
  .callback__title {
    font-size: 2rem;
  }
}

@media (max-width: 479px) {
  .callback {
    padding: 5rem 2rem 4rem;
  }

  .callback__title {
    font-size: 1.8rem;
    margin-bottom: 4rem;
  }

  .callback__form-inner {
    margin-bottom: 4rem;

    grid-template-rows: 4.5rem 4.5rem;
    grid-row-gap: 2.5rem;
  }
}