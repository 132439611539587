.side {
  padding: 0 1.5rem 0 1.2rem;
  background-color: $blue;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16);

  position: fixed;
  right: 0;
  top: 25%;
  z-index: 3;
}

.side__list-item {
  padding: 2rem 0;
}

.side__list-item + .side__list-item {
  border-top: 1px solid #254898;
}

.side__list-link {
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  svg {
    fill: $white;
  }
}

.side__list-item.price {
  border-color: transparent;
  padding: 0;

  position: fixed;
  top: 20%;
  left: 0;
  z-index: 3;

  a {
    padding: 3rem 1rem 3rem 1.5rem;
    background-color: $blue;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    font-size: 1.6rem;
    font-weight: 700;
    color: $white;
    text-transform: uppercase;
    writing-mode: vertical-lr;
    transform: rotate(-180deg);
  }

  svg {
    display: none;
  }
}

.side__list-cart-number {
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 18px;
  height: 18px;
  background-color: #bf334d;
  border-radius: 50%;
  font-size: 1.1rem;
  font-weight: 400;
  color: $white;

  position: absolute;
  right: -7px;
  top: -7px;
}

@media (max-width: 1599px) {
  .side__list-link {

    .side__icon-phone {
      width: 2rem;
      height: 2rem;
    }

    .side__icon-cart {
      width: 2.2rem;
      height: 2.2rem;
    }
  }

  .side__list-cart-number {

    min-width: 15px;
    height: 15px;
    font-size: 1rem;

    position: absolute;
    right: -7px;
    top: -7px;
  }

  .side {
    padding: 0 1rem;
  }

  .side__list-item.price a {
    font-size: 1.4rem;
    padding: 2rem .7rem 2rem 1rem;
  }
}

@media (max-width: 1199px) {
  .side__list-item.price a {
    font-size: 1.2rem;
  }

  .side__list-item.price {
    top: 12rem;
  }
}

@media (max-width: 575px) {
  .side__list-item.price {
    padding: 0 1rem;
    border-color: #254898;
    position: static;

    a {
      padding: 0;
      transform: none;
      box-shadow: none;
      writing-mode: horizontal-tb;
    }

    svg {
      display: block;
    }

    span {
      display: none;
    }
  }

  .side {
    padding: 1.2rem 2rem 1rem;

    width: 100%;
    position: fixed;
    right: auto;
    left: 0;
    top: auto;
    bottom: 0;
  }

  .side__list {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .side__list-item {
    padding: 0 1rem;
  }

  .side__list-item + .side__list-item {
    border-top: none;
  }

  .side__list-link {

    .side__icon-phone {
      width: 2.2rem;
      height: 2.2rem;
    }

    .side__icon-cart {
      width: 2.2rem;
      height: 2.2rem;
    }

    .side__icon-price {
      width: 2.2rem;
      height: 2.2rem;
    }
  }
}