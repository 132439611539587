.modal-fade {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .7);

  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;

  overflow-y: auto;
  overflow-x: hidden;
}

.modal {
  margin: 5rem auto;
  width: 60rem;
  background-color: $white;

  position: relative;

  &--price {
    width: 145rem;
    margin: 0 auto;
  }
}

.modal-close {
  display: block;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;

  width: 2rem;
  height: 2rem;

  background: none;
  border: none;
  cursor: pointer;
  padding: 0;

  &:before,
  &:after {
    content: '';
    height: 2rem;
    width: 2px;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -1px;
    background-color: $blue;
  }

  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}

@media (max-width: 1499px) {
  .modal--price {
    width: auto;
  }
}

@media (max-width: 1199px) {
  .modal {
    margin: 3rem auto;

    &--price {
      width: auto;
      margin: 0 auto;
    }
  }
}

@media (max-width: 639px) {
  .modal {
    width: auto;
    margin: 2rem 1rem;

    &--price {
      width: auto;
      margin: 0 auto;
    }
  }
}