.nav__list {
  display: flex;
}

.nav__item {
  margin-left: 2.8rem;

  &:first-of-type {
    margin-left: 0;
  }
}

.nav__link {
  font-size: 1.6rem;
  font-weight: 700;
  text-transform: uppercase;
}

@media (max-width: 1359px) {
  .nav__link {
    font-size: 1.5rem;
  }
}

@media (max-width: 1269px) {
  .nav__link {
    font-size: 1.4rem;
  }

  .nav__item {
    margin-left: 2rem;
  }
}

@media (max-width: 1159px) {
  .nav {
    display: none;

    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    background-color: $white;

    z-index: 4;
  }

  .nav__list {
    flex-direction: column;
    justify-content: center;

    position: absolute;
    top: 15%;
    left: 10%;
  }

  .nav__item {
    margin-left: 0;
    margin-bottom: 2rem;
  }

  .nav__link {
    color: $blue;
    font-size: 2rem;
  }
}

@media (max-width: 767px) {
  .nav__link {
    font-size: 1.8rem;
  }
}

@media (max-width: 575px) {
  .nav__link {
    font-size: 1.6rem;
  }
}