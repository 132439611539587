.about {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: 60rem 1fr;
  grid-column-gap: 6.5rem;

  &--mod {
    grid-column-gap: 3rem;
    grid-template-columns: 63rem 1fr;
  }
}

.about__item-img-wrapper {
  margin-top: -15rem;
  position: relative;

  span {
    position: absolute;
    font-weight: 800;
    font-size: 3.5rem;
  }

  span:first-of-type {
    bottom: 9%;
    left: 6%;
    color: $white;
  }

  span:last-of-type {
    color: $blue;
    right: 6%;
    top: -2%;
  }

  &--mod {
    margin: 0;
  }
}

.about__title {
  margin-bottom: 4rem;
  font-weight: 700;
  font-size: 1.9rem;
  color: $blue-dark;
}

.about__text {
  margin-bottom: 3rem;
}

.about__link {
  padding-bottom: .9rem;
  font-size: 1.9rem;
  font-weight: 500;

  position: relative;

  &:after {
    content: '';
    display: block;
    width: 40%;
    height: 3px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #bf334d;
    transition: width .3s ease-out;
  }

  &:hover:after {
    width: 100%;
  }

  span {
    margin-left: .5rem;
    font-family: 'Roboto', sans-serif;
    font-size: 1.6rem;
    font-weight: 400;
  }
}

.about__text-overflow {
  height: 32rem;
  overflow-y: auto;
}

.about__text-overflow::-webkit-scrollbar {
  background-color: #f0f0f0;
  width: 1rem;
}

.about__text-overflow::-webkit-scrollbar-thumb {
  background-color: $blue;
}

.about__text-overflow div {
  padding: .5rem 1.5rem 0  0;
  font-size: 1.6rem;
}

.about__text-overflow p {
  margin: 0 0 2rem 0;

  &:last-of-type {
    margin-bottom: 0;
  }
}

@media (max-width: 1639px) {
  .about__item-img-wrapper span {
    font-size: 3rem;
  }
}

@media (max-width: 1499px) {
  .about__item-img-wrapper {
    margin: 0;

    span {
      font-size: 2.5rem;
    }
  }

  .about {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;

    &--mod {
      grid-column-gap: 3rem;
      grid-template-columns: 55rem 1fr;
    }
  }
}

@media (max-width: 1379px) {
  .about__item-img-wrapper span {
    font-size: 2.2rem;
  }

  .about {
    grid-column-gap: 0;
  }
}

@media (max-width: 1199px) {
  .about {
    padding-left: 0;
  }
}

@media (max-width: 1159px) {
  .about {
    grid-template-columns: 1fr;
    grid-row-gap: 5rem;
  }

  .about__item-img-wrapper span {
    font-size: 3rem;
  }
}

@media (max-width: 819px) {
  .about__item-img-wrapper span {
    font-size: 2.5rem;
  }

  .about__text-overflow::-webkit-scrollbar {
    width: .5rem;
  }
}

@media (max-width: 679px) {
  .about__item-img-wrapper span {
    font-size: 2rem;
  }

  .about__text-overflow div {
    padding: .5rem 1rem 0  0;
  }
}

@media (max-width: 559px) {
  .about__item-img-wrapper span {
    font-size: 1.8rem;
  }

  .about__item-img-wrapper span:first-of-type {
    left: 5%;
  }
}

@media (max-width: 493px) {
  .about__item-img-wrapper span {
    font-size: 1.6rem;
  }
}

@media (max-width: 429px) {
  .about__item-img-wrapper span {
    display: none;
  }
}
