.recommendation {
  margin-top: 7rem;
  padding-left: 4.5rem;
  padding-right: 15rem;
}

.recommendation__banner {
  display: block;
  height: 100%;
  width: 36rem;
  max-width: 100%;
  position: relative;

  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;
  text-decoration: none;
  color: $white;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0.6;
    z-index: 1;
    background-color: $blue;
    transition: opacity .4s linear;
  }
}

.recommendation__banner-content {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
  padding: 10rem 2rem 2rem 3rem;
}

.recommendation__banner-title {
  font-weight: 700;
  font-size: 3.5rem;
  line-height: 1.1;
}

.recommendation__banner-link {
  width: max-content;
  padding-bottom: .5rem;
  font-size: 1.8rem;
  font-weight: 500;

  position: absolute;
  bottom: 5%;
  right: 9%;

  &:after {
    content: '';
    display: block;
    width: 40%;
    height: 3px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #bf334d;
    transition: width .4s linear;
  }

  span {
    margin-left: .5rem;
    font-family: 'Roboto', sans-serif;
    font-size: 1.6rem;
    font-weight: 400;
  }
}

.recommendation__banner:hover .recommendation__banner-link:after {
  width: 100%;
}

.recommendation__banner:hover:after {
  opacity: 0.8;
}

@media (max-width: 1499px) {
  .recommendation {
    padding: 0 4rem;
  }
}

@media (max-width: 1399px) {
  .recommendation__banner-content {
    padding: 7rem 2rem 2rem;
  }

  .recommendation__banner-title {
    font-size: 3rem;
  }
}

@media (max-width: 1299px) {
  .recommendation {
    padding: 0 3rem 0 2rem;
  }

  .recommendation__banner-title {
    font-size: 2.8rem;
  }

  .recommendation__banner-link {
    font-size: 1.6rem;

    span {
      font-size: 1.4rem;
      margin-left: 0;
    }
  }
}

@media (max-width: 1199px) {
  .recommendation {
    padding: 0 1rem;
  }

  .recommendation__banner-content {
    padding: 5rem 1.5rem 2rem;
  }
}

@media (max-width: 991px) {
  .recommendation__banner-title {
    font-size: 2.5rem;
  }

  .recommendation {
    padding: 0;
  }
}

@media (max-width: 575px) {
  .recommendation__banner {
    height: 30rem;
    max-height: 100%;
    margin: 3rem auto 0;
  }
}