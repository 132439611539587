.header {
  padding-top: 1rem;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;

  color: $white;

  svg {
    fill: $white;
  }
}

.header--blue {
  color: $blue;

  svg {
    fill: $blue;
  }
}

.header__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header__left {
  display: flex;
  align-items: center;
}

.header__logo {
  margin-right: 8rem;

  &--small {
    width: 11rem;
    height: 11rem;
  }
}

.header__catalog-wrapper {
  position: relative;
}

.header__catalog-btn {
  display: flex;
  align-items: center;

  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 700;
  color: inherit;

  svg {
    margin-right: 1.4rem;
  }

  &:hover span:after {
    width: 100%;
  }
}

.header__catalog-btn span {
  padding: 9px 0;
  position: relative;

  &:after {
    content: '';
    display: block;
    width: 40%;
    height: 3px;

    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #bf334d;
    transition: width 0.3s ease-out;
  }
}

//action

.header__action {
  display: flex;
}

.header__action-item {
  display: flex;
  padding-right: 2rem;

  position: relative;

  &:nth-of-type(2) {
    padding-right: 0;
  }

  &:last-of-type {
    display: none;
  }
}

.header__action-item + .header__action-item {
  padding-left: 2rem;
  border-left: 1px solid $white;
}

.header__action-link {
  display: flex;
  align-items: center;

  font-weight: 700;
  text-transform: uppercase;
}

.header__action-icon__phone {
  margin-right: 1rem;
}

.header__action-icon__dropdown {
  margin-left: 1rem;
  transition: transform .2s linear;
}

.header__action-icon__dropdown.rotate {
  transform: rotate(180deg);
}

.header__action-icon__search {
  margin-right: .8rem;
}

@media (max-width: 1679px) {
  .header__logo {
    margin-right: 5rem;
  }
}

@media (max-width: 1599px) {
  .header__action-link span {
    display: none;
  }

  .header__action-icon__dropdown {
    display: none;
  }

  .header__action-icon__search {
    margin-right: 0;
  }

  .header__action-link .header__action-icon__phone {
    margin-right: 0;
  }
}

@media (max-width: 1359px) {
  .header__logo {
    width: 11rem;
    height: 11rem;
  }

  .header__catalog-btn span {
    font-size: 1.5rem;
  }

  .header__catalog-btn svg {
    width: 2rem;
    height: 2rem;
  }
}

@media (max-width: 1269px) {
  .header__logo {
    margin-right: 3rem;
    width: 10rem;
    height: 10rem;
  }

  .header__catalog-btn span {
    font-size: 1.4rem;
  }
}

@media (max-width: 1159px) {

  .header__action-item:last-of-type {
    display: block;
    padding-right: 0;
  }

  .header__action-item:nth-of-type(2) {
    padding-right: 2rem;
  }
}

@media (max-width: 991px) {
  .header__logo {
    width: 8rem;
    height: 8rem;
  }
}

@media (max-width: 575px) {
  .header {
    padding: 0;
    position: fixed;
    top: 0;
    left: 0;
    background-color: $white;

    color: $blue;

    svg {
      fill: $blue;
    }
  }

  .header__action-item+.header__action-item {
    border-color: $blue;
  }

  .header__logo {
    margin-right: 0;
    width: 5rem;
    height: 5rem;
  }

  .header__catalog-wrapper {
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
  }

  .header__catalog-btn {
    padding: 1rem 0;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: $blue;
    color: $white;

    svg {
      fill: $white;
    }

    span {
      padding: 0;
    }
  }

  .header__catalog-btn span:after {
    display: none;
  }
}