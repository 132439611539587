.cart {
  padding: 6rem 3rem 3rem 4rem;
}

.cart__title {
  margin-bottom: 4rem;
  font-weight: 700;
  font-size: 2.5rem;
  color: $blue;
  text-transform: uppercase;
  text-align: center;
}

.cart__items-overflow {
  padding: .5rem 2.5rem 0  0;
  margin-bottom: 3rem;
  height: 37rem;
  overflow-y: auto;
}

.cart__items-overflow::-webkit-scrollbar {
  background-color: #f7f7f7;
  width: 6px;
  border: 1px solid $white;
}

.cart__items-overflow::-webkit-scrollbar-thumb {
  background-color: $blue;
  border-radius: 6px;
}

.cart__item {
  padding-bottom: 3rem;
  display: flex;
  justify-content: space-between;

  img {
    display: block;
    margin-right: 2.5rem;
    max-width: 100%;
    height: auto;
    object-fit: cover;
  }

  &:last-of-type {
    padding-bottom: 0;
  }
}

.cart__item + .cart__item {
  border-top: 1px solid #ebebeb;
  padding-top: 3rem;
}

.cart__item-col {
  display: flex;
  align-items: center;
}

.cart__item-title {
  font-weight: 900;
  font-size: 1.9rem;
  color: $blue-dark;
}

.cart__item-price {
  margin-right: 6rem;
  font-weight: 900;
  font-size: 1.9rem;
  color: #193db9;
}

.cart__item-close {
  display: block;
  position: relative;

  width: 1.5rem;
  height: 1.5rem;

  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  outline: none;

  &:before,
  &:after {
    content: ' ';
    height: 1.5rem;
    width: 1px;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -.5px;
    background-color: $blue;
  }

  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}

.cart__total-price {
  margin-bottom: 5rem;
  color: $blue-dark;
  font-weight: 900;
  font-size: 1.9rem;
}

.cart__btn {
  display: block;
  max-width: max-content;
  margin: 0 auto;
}

@media (max-width: 1299px) {
  .cart__title {
    font-size: 2.2rem;
  }

  .cart__item-price, .cart__item-title {
    font-size: 1.8rem;
  }
}

@media (max-width: 639px) {
  .cart__item-price {
    margin-right: 3rem;
  }

  .cart__total-price {
    margin-bottom: 3rem;
    font-size: 1.8rem;
  }

  .cart__title {
    margin-bottom: 3rem;
  }
}

@media (max-width: 529px) {
  .cart__item {
    padding-bottom: 2rem;
    flex-direction: column;

    &:last-of-type {
      padding-bottom: 0;
    }
  }

  .cart__item + .cart__item {
    padding-top: 2rem;
  }

  .cart__item-col:first-of-type {
    margin-bottom: 1rem;
  }

  .cart__item-price {
    margin-right: 5rem;
  }

  .cart__items-overflow {
    padding: 0 1rem 0 0;
  }

  .cart {
    padding: 3rem 1rem 3rem 2rem;
  }
}

@media (max-width: 429px) {
  .cart__title {
    font-size: 2rem;
  }

  .cart__item-price, .cart__item-title {
    font-size: 1.6rem;
  }

  .cart__total-price {
    font-size: 1.6rem;
  }
}