.title {
  font-size: 3.3rem;
  color: $blue;
  font-weight: 700;

  position: relative;
  text-align: center;
}

.title--main {
  font-size: 4.4rem;
  text-transform: uppercase;
  text-align: left;
}

.title--margin {
  margin-left: 0;

  @media (max-width: 1899px) {
    margin-left: 5rem;
  }

  @media (max-width: 1199px) {
    margin-left: 0;
  }
}

.title__border {
  padding: 0 0 .3rem 0;
  position: relative;

  &:after {
    content: '';
    width: 70%;
    height: 6px;
    background-color: #bf334d;

    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
}

.title__sub {
  font-family: 'Futura PT Extra', sans-serif;
  font-weight: 800;
  font-size: 29.3rem;
  text-transform: lowercase;

  line-height: 1.1;
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #dddddd;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -45%);
  z-index: -1;
}

.title__sub--main {
  left: -2%;
  transform: none;
  bottom: -100%;
  top: auto;
  text-transform: uppercase;
}

@media (max-width: 1699px) {
  .title__sub {
    font-size: 25rem;
  }
}

@media (max-width: 1399px) {
  .title__sub {
    font-size: 22rem;
  }

  .title--main {
    font-size: 4rem;
  }
}

@media (max-width: 1299px) {
  .title__sub {
    font-size: 20rem;
  }

  .title {
    font-size: 3rem;
  }

  .title__border {
    padding: 0 0 1px 0;

    &:after {
      height: 3px;
    }
  }
}

@media (max-width: 1199px) {
  .title__sub {
    font-size: 18rem;
  }
}

@media (max-width: 991px) {
  .title__sub {
    font-size: 14rem;
  }

  .title {
    font-size: 2.8rem;
  }
}

@media (max-width: 767px) {
  .title__sub {
    display: none;
  }
}

@media (max-width: 575px) {
  .title {
    font-size: 2.5rem;
  }
}

@media (max-width: 369px) {
  .title {
    font-size: 2.2rem;
  }
}