.contact-us {
  position: relative;
  z-index: 1;
  color: $white;

  svg {
    fill: $white;
  }

  a {
    display: flex;
    align-items: center;
    font-size: 1.9rem;
    font-weight: 500;

    svg {
      margin-right: .9rem;
    }
  }
}

// mod contacts
.contact-us--blue {

  svg {
    fill: $blue-dark;
  }

  color: $blue-dark;

  .contact-us__subtitle {
    color: $blue;
  }

  .contact-us__title {
    color: $blue;
  }
}
//

.contact-us__subtitle {
  margin-bottom: 1rem;
  color: #afafaf;
  font-size: 1.6rem;
  font-weight: 400;

  &.first {
    margin-bottom: 0;
  }
}

.contact-us__title {
  margin-bottom: 3rem;
  font-size: 3.3rem;
  font-weight: 700;
}

.contact-us__address {
  margin-bottom: 3rem;
  max-width: 32rem;
  font-style: normal;
  font-size: 1.9rem;
  font-weight: 500;
  display: grid;
  grid-template-columns: auto auto;
  align-items: flex-start;

  svg {
    margin-top: .5rem;
    margin-right: 1.5rem;
  }
}

.contact-us__phone-lists {
  margin-bottom: 3rem;
  display: grid;
  grid-template-columns: max-content max-content;
  grid-column-gap: 5rem;

  li {
    margin-bottom: .5rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.contact-us__mail-list {
  margin-bottom: 3rem;

  li {
    margin-bottom: 1rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.contact-us__link {
  padding: 1.5rem 3.5rem;
  display: block;
  width: max-content;
  background-color: $white;
  color: $blue;
  font-size: 1.9rem;
  font-weight: 400;

  transition: background-color .2s linear;

  &:hover {
    background-color: darken($white, 5%);
  }
}

@media (max-width: 1499px) {
  .contact-us a {
    font-size: 1.8rem;
  }

  .contact-us__address {
    font-size: 1.8rem;
    max-width: 30rem;
  }

  .contact-us__title {
    font-size: 3.2rem;
  }
}

@media (max-width: 1199px) {
  .contact-us a {
    font-size: 1.7rem;
  }

  .contact-us__address {
    font-size: 1.7rem;
  }

  .contact-us__title {
    font-size: 3rem;
  }

  .contact-us__subtitle {
    font-size: 1.5rem;
  }
}

@media (max-width: 899px) {
  .contact-us--blue {
    margin: 0 auto;
    width: max-content;
  }
}

@media (max-width: 575px) {
  .contact-us a {
    font-size: 1.6rem;
  }

  .contact-us__address {
    font-size: 1.6rem;
  }

  .contact-us__title {
    font-size: 2.8rem;
  }

  .contact-us__subtitle {
    font-size: 1.4rem;
  }

  .contact-us__link {
    padding: 1.4rem 3rem;
  }

  .contact-us--blue {
    margin: 0;
    width: 100%;
  }
}

@media (max-width: 449px) {
  .contact-us__phone-lists {
    grid-template-columns: max-content;
    grid-row-gap: 2rem;

    li {
      margin-bottom: 1rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .contact-us__title {
    font-size: 2.5rem;
  }
}