.our-services {
  margin: 10rem 0 0 0;
}

.our-services__text {
  margin-bottom: 5rem;
}

.our-services__list {
  margin-bottom: 8rem;
}

.our-services__list-item + .our-services__list-item{
  margin-top: 8rem;
}

.our-services__list-item a {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 23px 44px 21px 22px;
  background-color: #f8faff;
  box-shadow: 0 3px 60px rgba(0, 0, 0, .16);
}

.our-services__list-item img {
  margin-right: 133px;
  object-fit: contain;
}

.our-services__list-item__title {
  font-size: 2.5rem;
  font-weight: 700;
  color: $blue;
}

.our-services__list-item__left {
  display: flex;
  align-items: center;
}

.our-services__text-overflow {
  height: 23rem;
  overflow-y: auto;
}

.our-services__text-overflow::-webkit-scrollbar {
  background-color: #f0f0f0;
  width: 1rem;
}

.our-services__text-overflow::-webkit-scrollbar-thumb {
  background-color: $blue;
}

.our-services__text-overflow div {
  padding: .5rem 1.5rem 0  0;
  font-size: 1.9rem;
}

.our-services__text-overflow p {
  margin: 0 0 2rem 0;

  &:last-of-type {
    margin-bottom: 0;
  }
}

@media (max-width: 1399px) {
  .our-services__list-item__title {
    font-size: 2.2rem;
  }
}

@media (max-width: 1299px) {
  .our-services__list-item img {
    margin-right: 8rem;
    width: 12rem;
    height: 12rem;
  }

  .our-services__list-item svg {
    width: 32px;
    height: 11px;
  }
}

@media (max-width: 1199px) {
  .our-services__list-item img {
    margin-right: 5rem;
    width: 10rem;
    height: 10rem;
  }

  .our-services__list-item__title {
    font-size: 2rem;
  }
}

@media (max-width: 767px) {
  .our-services__text-overflow div {
    font-size: 1.8rem;
  }
}

@media (max-width: 649px) {
  .our-services__list-item a {
    display: block;
  }

  .our-services__list-item img {
    margin-right: 2rem;
    width: 8rem;
    height: 8rem;
  }

  .our-services__list-item__left {
    margin-bottom: 2rem;
    justify-content: space-between;
  }

  .our-services__list-item svg {
    margin-left: 2rem;
  }
}

@media (max-width: 575px) {
  .our-services__list-item__title {
    font-size: 1.8rem;
    text-align: center;
  }

  .our-services__list-item a {
    padding: 2rem;
  }

  .our-services__text {
    font-size: 1.8rem;
  }

  .our-services__text-overflow div {
    font-size: 1.7rem;
    padding: .5rem 1rem 0  0;
  }
}

@media (max-width: 369px) {
  .our-services__list-item a {
    padding: 2rem 1.5rem 2rem 1rem;
  }

  .our-services__list-item img {
    margin-right: 1.5rem;
  }
}

