@charset "UTF-8";
/*!
 * Bootstrap Grid v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*::before,
*::after {
  box-sizing: inherit; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

@font-face {
  font-family: 'Futura PT Book';
  src: url("../fonts/FuturaPT-Book.woff2") format("woff2"), url("../fonts/FuturaPT-Book.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Futura PT';
  src: url("../fonts/FuturaPT-Medium.woff2") format("woff2"), url("../fonts/FuturaPT-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Futura PT';
  src: url("../fonts/FuturaPT-Bold.woff2") format("woff2"), url("../fonts/FuturaPT-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Futura PT Extra';
  src: url("../fonts/FuturaPT-ExtraBold.woff2") format("woff2"), url("../fonts/FuturaPT-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Futura PT';
  src: url("../fonts/FuturaPT-Heavy.woff2") format("woff2"), url("../fonts/FuturaPT-Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/Roboto-Regular.woff2") format("woff2"), url("../fonts/Roboto-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

*,
*:before,
*:after {
  box-sizing: border-box; }

html {
  font-size: 62.5%;
  /* 62.5% от 16px(дефолт знач шрифта в браузере) = 10px */ }

body {
  margin: 0;
  font-family: 'Futura PT', sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.3;
  color: #23304d; }

body.lock {
  overflow: hidden; }

@media (max-width: 767px) {
  body.lock-mobile {
    overflow: hidden; } }

.main {
  overflow: hidden; }

.container {
  width: 100%;
  max-width: 1740px;
  margin: 0 auto;
  padding: 0 2rem; }

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0); }

.img {
  display: block;
  max-width: 100%;
  height: auto; }

h1, h2, h3, h4, h5, h6 {
  margin: 0; }

p {
  margin: 0; }

ul, ol {
  padding: 0;
  margin: 0;
  list-style: none; }

a {
  text-decoration: none;
  color: inherit; }

.map iframe {
  display: block;
  border: none;
  width: 100%;
  height: 100%; }

.map {
  height: 100%;
  background: url("../img/map-bg.jpg") no-repeat center;
  background-size: cover;
  width: 50%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1; }
  @media (max-width: 991px) {
    .map {
      right: auto;
      top: auto;
      bottom: 0;
      left: 0;
      width: 100%;
      height: auto; }
      .map iframe {
        height: 40rem; } }

.text-general {
  font-size: 1.9rem; }
  .text-general p {
    margin-bottom: 2rem; }
    .text-general p:last-of-type {
      margin-bottom: 0; }
  @media (max-width: 767px) {
    .text-general {
      font-size: 1.8rem; } }
  @media (max-width: 575px) {
    .text-general {
      font-size: 1.7rem; } }

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0); }

.btn {
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  font-family: 'Futura PT', sans-serif;
  background: none;
  border: 0;
  text-decoration: none;
  cursor: pointer; }
  .btn:focus {
    outline: none; }

.btn--classic {
  font-size: 1.9rem;
  color: #ffffff;
  background-color: #102a83;
  transition: background-color .2s linear;
  padding: 1.4rem 5rem; }
  .btn--classic:hover {
    background-color: #0d236c; }

@media (max-width: 1199px) {
  .btn--classic {
    font-size: 1.6rem;
    padding: 1.4rem 4rem; } }

.header {
  padding-top: 1rem;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  color: #ffffff; }
  .header svg {
    fill: #ffffff; }

.header--blue {
  color: #102a83; }
  .header--blue svg {
    fill: #102a83; }

.header__inner {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.header__left {
  display: flex;
  align-items: center; }

.header__logo {
  margin-right: 8rem; }
  .header__logo--small {
    width: 11rem;
    height: 11rem; }

.header__catalog-wrapper {
  position: relative; }

.header__catalog-btn {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 700;
  color: inherit; }
  .header__catalog-btn svg {
    margin-right: 1.4rem; }
  .header__catalog-btn:hover span:after {
    width: 100%; }

.header__catalog-btn span {
  padding: 9px 0;
  position: relative; }
  .header__catalog-btn span:after {
    content: '';
    display: block;
    width: 40%;
    height: 3px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #bf334d;
    transition: width 0.3s ease-out; }

.header__action {
  display: flex; }

.header__action-item {
  display: flex;
  padding-right: 2rem;
  position: relative; }
  .header__action-item:nth-of-type(2) {
    padding-right: 0; }
  .header__action-item:last-of-type {
    display: none; }

.header__action-item + .header__action-item {
  padding-left: 2rem;
  border-left: 1px solid #ffffff; }

.header__action-link {
  display: flex;
  align-items: center;
  font-weight: 700;
  text-transform: uppercase; }

.header__action-icon__phone {
  margin-right: 1rem; }

.header__action-icon__dropdown {
  margin-left: 1rem;
  transition: transform .2s linear; }

.header__action-icon__dropdown.rotate {
  transform: rotate(180deg); }

.header__action-icon__search {
  margin-right: .8rem; }

@media (max-width: 1679px) {
  .header__logo {
    margin-right: 5rem; } }

@media (max-width: 1599px) {
  .header__action-link span {
    display: none; }
  .header__action-icon__dropdown {
    display: none; }
  .header__action-icon__search {
    margin-right: 0; }
  .header__action-link .header__action-icon__phone {
    margin-right: 0; } }

@media (max-width: 1359px) {
  .header__logo {
    width: 11rem;
    height: 11rem; }
  .header__catalog-btn span {
    font-size: 1.5rem; }
  .header__catalog-btn svg {
    width: 2rem;
    height: 2rem; } }

@media (max-width: 1269px) {
  .header__logo {
    margin-right: 3rem;
    width: 10rem;
    height: 10rem; }
  .header__catalog-btn span {
    font-size: 1.4rem; } }

@media (max-width: 1159px) {
  .header__action-item:last-of-type {
    display: block;
    padding-right: 0; }
  .header__action-item:nth-of-type(2) {
    padding-right: 2rem; } }

@media (max-width: 991px) {
  .header__logo {
    width: 8rem;
    height: 8rem; } }

@media (max-width: 575px) {
  .header {
    padding: 0;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #ffffff;
    color: #102a83; }
    .header svg {
      fill: #102a83; }
  .header__action-item + .header__action-item {
    border-color: #102a83; }
  .header__logo {
    margin-right: 0;
    width: 5rem;
    height: 5rem; }
  .header__catalog-wrapper {
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1; }
  .header__catalog-btn {
    padding: 1rem 0;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #102a83;
    color: #ffffff; }
    .header__catalog-btn svg {
      fill: #ffffff; }
    .header__catalog-btn span {
      padding: 0; }
  .header__catalog-btn span:after {
    display: none; } }

.catalog-dropdown {
  display: none;
  padding: 2rem;
  background-color: #ffffff;
  box-shadow: 0 7px 6px rgba(0, 0, 0, 0.16);
  position: absolute;
  left: 0;
  top: 140%;
  z-index: 2; }
  .catalog-dropdown svg {
    margin-right: 1.5rem;
    width: 2.5rem; }

.catalog-dropdown__link {
  padding: .5rem 0;
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-family: 'Roboto', sans-serif;
  font-size: 2rem;
  color: #3e3e3e; }
  .catalog-dropdown__link:hover {
    color: #102a83; }

.catalog-dropdown__icon-rotate {
  transform: rotate(45deg); }

.tel-dropdown {
  width: 20rem;
  padding: 1.5rem 1rem;
  background-color: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  position: absolute;
  left: -1rem;
  top: 190%;
  z-index: 3; }
  .tel-dropdown svg {
    fill: #102a83; }

.tel-dropdown__item {
  margin-bottom: 1rem; }
  .tel-dropdown__item:last-of-type {
    margin-bottom: 0; }

.tel-dropdown__link {
  display: flex;
  align-items: center;
  font-weight: 700;
  color: #102a83; }

.search-dropdown {
  position: absolute;
  right: 0;
  top: 190%;
  z-index: 2; }
  .search-dropdown:after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: #ffffff; }

.search-dropdown--blue:after {
  background: #102a83; }

.search-dropdown__btn {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1; }

.search-dropdown__input {
  width: 32rem;
  font-family: 'Futura PT', sans-serif;
  font-style: normal;
  font-size: 1.9rem;
  font-weight: 400;
  color: #ffffff;
  padding: 0 3rem 1rem 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }
  .search-dropdown__input::placeholder {
    color: #ffffff; }
  .search-dropdown__input--blue {
    color: #102a83; }
    .search-dropdown__input--blue::placeholder {
      color: #102a83; }

@media (max-width: 1599px) {
  .tel-dropdown {
    right: 0;
    left: auto; }
  .search-dropdown__input {
    width: 25rem; } }

@media (max-width: 1199px) {
  .catalog-dropdown__link {
    font-size: 1.8rem; } }

@media (max-width: 991px) {
  .catalog-dropdown__link {
    font-size: 1.6rem; } }

@media (max-width: 767px) {
  .tel-dropdown {
    width: auto;
    box-shadow: none;
    padding: 0;
    position: static; }
  .search-dropdown {
    position: relative;
    width: 100%; }
    .search-dropdown:after {
      background: #102a83; }
  .search-dropdown__input {
    padding: 0 3rem .5rem 0;
    color: #102a83;
    width: 100%; }
    .search-dropdown__input::placeholder {
      color: #102a83; }
  .search-dropdown__btn {
    width: 2rem;
    height: 2rem;
    top: 10%;
    right: 1%; }
    .search-dropdown__btn svg {
      fill: #102a83;
      width: 2rem;
      height: 2rem; } }

@media (max-width: 575px) {
  .catalog-dropdown {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    position: static; } }

.nav__list {
  display: flex; }

.nav__item {
  margin-left: 2.8rem; }
  .nav__item:first-of-type {
    margin-left: 0; }

.nav__link {
  font-size: 1.6rem;
  font-weight: 700;
  text-transform: uppercase; }

@media (max-width: 1359px) {
  .nav__link {
    font-size: 1.5rem; } }

@media (max-width: 1269px) {
  .nav__link {
    font-size: 1.4rem; }
  .nav__item {
    margin-left: 2rem; } }

@media (max-width: 1159px) {
  .nav {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    z-index: 4; }
  .nav__list {
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 15%;
    left: 10%; }
  .nav__item {
    margin-left: 0;
    margin-bottom: 2rem; }
  .nav__link {
    color: #102a83;
    font-size: 2rem; } }

@media (max-width: 767px) {
  .nav__link {
    font-size: 1.8rem; } }

@media (max-width: 575px) {
  .nav__link {
    font-size: 1.6rem; } }

.nav-toggle {
  display: none;
  width: 24px;
  padding: 10px 0;
  font-size: 0;
  color: transparent;
  border: 0;
  background: 0 0;
  cursor: pointer;
  outline: 0; }

.nav-toggle__item {
  display: block;
  width: 100%;
  height: 2px;
  background-color: #ffffff;
  position: relative;
  z-index: 4; }
  .nav-toggle__item:before, .nav-toggle__item:after {
    content: "";
    width: 100%;
    height: 2px;
    background-color: #ffffff;
    position: absolute;
    left: 0;
    z-index: 1;
    transition: transform .2s linear; }
  .nav-toggle__item:before {
    top: -8px; }
  .nav-toggle__item:after {
    top: 8px; }

.nav-toggle__item--blue {
  background-color: #102a83; }
  .nav-toggle__item--blue:before, .nav-toggle__item--blue:after {
    background-color: #102a83; }

.nav-toggle.active .nav-toggle__item {
  background: none; }

.nav-toggle.active .nav-toggle__item:before {
  transform-origin: left top;
  transform: rotate(45deg);
  background-color: #102a83; }

.nav-toggle.active .nav-toggle__item:after {
  transform-origin: left bottom;
  transform: rotate(-45deg);
  background-color: #102a83; }

@media (max-width: 1159px) {
  .nav-toggle {
    display: block; } }

@media (max-width: 575px) {
  .nav-toggle__item {
    background-color: #102a83; }
    .nav-toggle__item:before, .nav-toggle__item:after {
      background-color: #102a83; } }

/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1; }
  .owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
    touch-action: manipulation;
    -moz-backface-visibility: hidden;
    /* fix firefox animation glitch */ }
  .owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0; }
  .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    /* fix for flashing background */
    -webkit-transform: translate3d(0px, 0px, 0px); }
  .owl-carousel .owl-wrapper,
  .owl-carousel .owl-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0); }
  .owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none; }
  .owl-carousel .owl-item img {
    display: block;
    width: 100%; }
  .owl-carousel .owl-nav.disabled,
  .owl-carousel .owl-dots.disabled {
    display: none; }
  .owl-carousel .owl-nav .owl-prev,
  .owl-carousel .owl-nav .owl-next,
  .owl-carousel .owl-dot {
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel .owl-nav button.owl-prev,
  .owl-carousel .owl-nav button.owl-next,
  .owl-carousel button.owl-dot {
    background: none;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit; }
  .owl-carousel.owl-loaded {
    display: block; }
  .owl-carousel.owl-loading {
    opacity: 0;
    display: block; }
  .owl-carousel.owl-hidden {
    opacity: 0; }
  .owl-carousel.owl-refresh .owl-item {
    visibility: hidden; }
  .owl-carousel.owl-drag .owl-item {
    touch-action: pan-y;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel.owl-grab {
    cursor: move;
    cursor: grab; }
  .owl-carousel.owl-rtl {
    direction: rtl; }
  .owl-carousel.owl-rtl .owl-item {
    float: right; }

/* No Js */
.no-js .owl-carousel {
  display: block; }

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out; }

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
			This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
			calculation of the height of the owl-item that breaks page layouts
		 */ }
  .owl-carousel .owl-item .owl-lazy {
    opacity: 0;
    transition: opacity 400ms ease; }
  .owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
    max-height: 0; }
  .owl-carousel .owl-item img.owl-lazy {
    transform-style: preserve-3d; }

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease; }

.owl-carousel .owl-video-play-icon:hover {
  transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%; }

.owl {
  position: relative; }

.owl-carousel.slide-one .item {
  width: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative; }
  .owl-carousel.slide-one .item:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: .8;
    z-index: 1;
    background-color: #31446e; }

.owl__inner {
  padding-left: 12.8rem;
  position: relative;
  z-index: 2;
  color: #ffffff; }

.owl__suptitle {
  font-weight: 500;
  font-size: 3.4rem; }

.owl__title {
  margin-bottom: 2rem;
  font-weight: 700;
  font-size: 8.1rem; }

.owl__text {
  margin-left: 1.4rem;
  max-width: 56rem;
  margin-bottom: 3rem;
  font-weight: 500;
  font-size: 1.6rem; }

.owl__link {
  margin-left: 2.7rem;
  padding-bottom: .9rem;
  font-size: 1.9rem;
  font-weight: 900;
  position: relative; }
  .owl__link:after {
    content: '';
    display: block;
    width: 40%;
    height: 3px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #bf334d;
    transition: width .3s ease-out; }
  .owl__link:hover:after {
    width: 100%; }
  .owl__link span {
    margin-left: 1rem;
    font-family: 'Roboto', sans-serif;
    font-size: 1.8rem;
    font-weight: 400; }

.owl-carousel.slide-one .owl-nav {
  position: absolute;
  bottom: 4.6%;
  left: 6.25%;
  z-index: 1; }

.owl-carousel.slide-one .owl-nav button.owl-prev,
.owl-carousel.slide-one .owl-nav button.owl-next {
  background-color: transparent;
  border: 2px solid #ffffff; }
  .owl-carousel.slide-one .owl-nav button.owl-prev:focus,
  .owl-carousel.slide-one .owl-nav button.owl-next:focus {
    outline: none; }
  .owl-carousel.slide-one .owl-nav button.owl-prev span,
  .owl-carousel.slide-one .owl-nav button.owl-next span {
    font-family: 'Roboto', sans-serif;
    color: #ffffff;
    padding: 0 1.8rem;
    font-size: 3.5rem;
    font-weight: 400; }

.owl-carousel.slide-one .owl-nav button.owl-prev {
  margin-right: 1.5rem; }

.slide-one .owl-dots {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%); }

.slide-one .owl-dot {
  padding: 2px 0 !important;
  margin-bottom: 3rem;
  background-color: #ffffff !important;
  width: 6rem;
  height: 2px;
  position: relative;
  z-index: 1; }
  .slide-one .owl-dot:last-of-type {
    margin-bottom: 0; }
  .slide-one .owl-dot:focus {
    outline: none; }
  .slide-one .owl-dot span {
    position: absolute;
    left: -2rem;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    font-family: 'Roboto', sans-serif;
    color: #ffffff;
    font-size: 1.9rem;
    font-weight: 400; }

.slide-one .owl-dot.active {
  width: 10rem; }

.owl__scroll-down {
  position: absolute;
  z-index: 1;
  bottom: 3%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center; }

.owl__scroll-down__text {
  margin-top: .8rem;
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: 500; }

@media (max-width: 1299px) {
  .owl__suptitle {
    font-size: 3rem; }
  .owl__title {
    font-size: 7.5rem; }
  .owl__inner {
    padding-left: 10rem; } }

@media (max-width: 1199px) {
  .owl__title {
    font-size: 6rem; }
  .owl__inner {
    padding-left: 5rem; } }

@media (max-width: 991px) {
  .owl__suptitle {
    font-size: 2.8rem; }
  .owl__title {
    font-size: 5.5rem; }
  .owl__inner {
    padding-left: 0; }
  .owl-carousel.slide-one .item {
    min-height: 80rem; } }

@media (max-width: 767px) {
  .owl__text {
    margin-left: 0;
    text-align: center; }
  .owl__suptitle {
    font-size: 2.5rem; }
  .owl__title {
    font-size: 5rem; }
  .owl__link {
    font-size: 1.6rem; }
    .owl__link span {
      font-size: 1.4rem;
      margin-left: .5rem; }
  .owl__scroll-down {
    display: none; } }

@media (max-width: 639px) {
  .owl__suptitle {
    font-size: 2.2rem; }
  .owl__title {
    font-size: 4.5rem; }
  .owl__link {
    margin-left: 0; } }

@media (max-width: 575px) {
  .owl__suptitle {
    font-size: 2rem; }
  .owl__title {
    font-size: 4rem; } }

.popup-fade {
  display: none; }

.popup-close {
  display: none; }

@media (max-width: 767px) {
  .popup-fade:before {
    content: '';
    background-color: #000000;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    z-index: 9; }
  .popup {
    display: flex;
    justify-content: center;
    width: 30rem;
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ffffff;
    padding: 7rem 2rem 5rem;
    z-index: 99;
    opacity: 1; }
    .popup--search {
      padding: 8rem 4rem 6rem; }
  .popup-close {
    display: block;
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    width: 2rem;
    height: 2rem;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0; }
    .popup-close:before, .popup-close:after {
      content: ' ';
      height: 2rem;
      width: 2px;
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -1px;
      background-color: #102a83; }
    .popup-close:before {
      transform: rotate(45deg); }
    .popup-close:after {
      transform: rotate(-45deg); } }

.side {
  padding: 0 1.5rem 0 1.2rem;
  background-color: #102a83;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  position: fixed;
  right: 0;
  top: 25%;
  z-index: 3; }

.side__list-item {
  padding: 2rem 0; }

.side__list-item + .side__list-item {
  border-top: 1px solid #254898; }

.side__list-link {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; }
  .side__list-link svg {
    fill: #ffffff; }

.side__list-item.price {
  border-color: transparent;
  padding: 0;
  position: fixed;
  top: 20%;
  left: 0;
  z-index: 3; }
  .side__list-item.price a {
    padding: 3rem 1rem 3rem 1.5rem;
    background-color: #102a83;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    font-size: 1.6rem;
    font-weight: 700;
    color: #ffffff;
    text-transform: uppercase;
    writing-mode: vertical-lr;
    transform: rotate(-180deg); }
  .side__list-item.price svg {
    display: none; }

.side__list-cart-number {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 18px;
  height: 18px;
  background-color: #bf334d;
  border-radius: 50%;
  font-size: 1.1rem;
  font-weight: 400;
  color: #ffffff;
  position: absolute;
  right: -7px;
  top: -7px; }

@media (max-width: 1599px) {
  .side__list-link .side__icon-phone {
    width: 2rem;
    height: 2rem; }
  .side__list-link .side__icon-cart {
    width: 2.2rem;
    height: 2.2rem; }
  .side__list-cart-number {
    min-width: 15px;
    height: 15px;
    font-size: 1rem;
    position: absolute;
    right: -7px;
    top: -7px; }
  .side {
    padding: 0 1rem; }
  .side__list-item.price a {
    font-size: 1.4rem;
    padding: 2rem .7rem 2rem 1rem; } }

@media (max-width: 1199px) {
  .side__list-item.price a {
    font-size: 1.2rem; }
  .side__list-item.price {
    top: 12rem; } }

@media (max-width: 575px) {
  .side__list-item.price {
    padding: 0 1rem;
    border-color: #254898;
    position: static; }
    .side__list-item.price a {
      padding: 0;
      transform: none;
      box-shadow: none;
      writing-mode: horizontal-tb; }
    .side__list-item.price svg {
      display: block; }
    .side__list-item.price span {
      display: none; }
  .side {
    padding: 1.2rem 2rem 1rem;
    width: 100%;
    position: fixed;
    right: auto;
    left: 0;
    top: auto;
    bottom: 0; }
  .side__list {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .side__list-item {
    padding: 0 1rem; }
  .side__list-item + .side__list-item {
    border-top: none; }
  .side__list-link .side__icon-phone {
    width: 2.2rem;
    height: 2.2rem; }
  .side__list-link .side__icon-cart {
    width: 2.2rem;
    height: 2.2rem; }
  .side__list-link .side__icon-price {
    width: 2.2rem;
    height: 2.2rem; } }

.modal-fade {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  overflow-y: auto;
  overflow-x: hidden; }

.modal {
  margin: 5rem auto;
  width: 60rem;
  background-color: #ffffff;
  position: relative; }
  .modal--price {
    width: 145rem;
    margin: 0 auto; }

.modal-close {
  display: block;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  width: 2rem;
  height: 2rem;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0; }
  .modal-close:before, .modal-close:after {
    content: '';
    height: 2rem;
    width: 2px;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -1px;
    background-color: #102a83; }
  .modal-close:before {
    transform: rotate(45deg); }
  .modal-close:after {
    transform: rotate(-45deg); }

@media (max-width: 1499px) {
  .modal--price {
    width: auto; } }

@media (max-width: 1199px) {
  .modal {
    margin: 3rem auto; }
    .modal--price {
      width: auto;
      margin: 0 auto; } }

@media (max-width: 639px) {
  .modal {
    width: auto;
    margin: 2rem 1rem; }
    .modal--price {
      width: auto;
      margin: 0 auto; } }

.modal-fade {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  overflow-y: auto;
  overflow-x: hidden; }

.modal {
  margin: 5rem auto;
  width: 60rem;
  background-color: #ffffff;
  position: relative; }
  .modal--price {
    width: 145rem;
    margin: 0 auto; }

.modal-close {
  display: block;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  width: 2rem;
  height: 2rem;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0; }
  .modal-close:before, .modal-close:after {
    content: '';
    height: 2rem;
    width: 2px;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -1px;
    background-color: #102a83; }
  .modal-close:before {
    transform: rotate(45deg); }
  .modal-close:after {
    transform: rotate(-45deg); }

@media (max-width: 1499px) {
  .modal--price {
    width: auto; } }

@media (max-width: 1199px) {
  .modal {
    margin: 3rem auto; }
    .modal--price {
      width: auto;
      margin: 0 auto; } }

@media (max-width: 639px) {
  .modal {
    width: auto;
    margin: 2rem 1rem; }
    .modal--price {
      width: auto;
      margin: 0 auto; } }

.callback {
  padding: 7rem 8rem 8rem; }

.callback__title {
  margin-bottom: 5.5rem;
  font-weight: 700;
  font-size: 2.5rem;
  color: #102a83;
  text-transform: uppercase;
  text-align: center; }

.callback__form-inner {
  margin-bottom: 5.3rem;
  display: grid;
  grid-template-rows: 6.4rem 6.4rem;
  grid-row-gap: 3.4rem; }

.callback__form-input {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-size: 1.9rem;
  font-weight: 400;
  color: #000000;
  outline: 0;
  transition: border .2s linear;
  padding: 0 1.6rem;
  background: none;
  border: 1px solid #707070;
  border-radius: 5px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }
  .callback__form-input::placeholder {
    color: #6a6a6a; }
  .callback__form-input:focus {
    border-color: #102a83; }

.callback__btn {
  margin: 0 auto;
  display: block; }

@media (max-width: 1299px) {
  .callback {
    padding: 5rem 7rem; }
  .callback__title {
    margin-bottom: 5rem;
    font-size: 2.2rem; }
  .callback__form-inner {
    margin-bottom: 5rem;
    grid-template-rows: 5rem 5rem;
    grid-row-gap: 3rem; }
  .callback__form-input {
    font-size: 1.6rem; } }

@media (max-width: 639px) {
  .callback__title {
    font-size: 2rem; } }

@media (max-width: 479px) {
  .callback {
    padding: 5rem 2rem 4rem; }
  .callback__title {
    font-size: 1.8rem;
    margin-bottom: 4rem; }
  .callback__form-inner {
    margin-bottom: 4rem;
    grid-template-rows: 4.5rem 4.5rem;
    grid-row-gap: 2.5rem; } }

.cart {
  padding: 6rem 3rem 3rem 4rem; }

.cart__title {
  margin-bottom: 4rem;
  font-weight: 700;
  font-size: 2.5rem;
  color: #102a83;
  text-transform: uppercase;
  text-align: center; }

.cart__items-overflow {
  padding: .5rem 2.5rem 0  0;
  margin-bottom: 3rem;
  height: 37rem;
  overflow-y: auto; }

.cart__items-overflow::-webkit-scrollbar {
  background-color: #f7f7f7;
  width: 6px;
  border: 1px solid #ffffff; }

.cart__items-overflow::-webkit-scrollbar-thumb {
  background-color: #102a83;
  border-radius: 6px; }

.cart__item {
  padding-bottom: 3rem;
  display: flex;
  justify-content: space-between; }
  .cart__item img {
    display: block;
    margin-right: 2.5rem;
    max-width: 100%;
    height: auto;
    object-fit: cover; }
  .cart__item:last-of-type {
    padding-bottom: 0; }

.cart__item + .cart__item {
  border-top: 1px solid #ebebeb;
  padding-top: 3rem; }

.cart__item-col {
  display: flex;
  align-items: center; }

.cart__item-title {
  font-weight: 900;
  font-size: 1.9rem;
  color: #23304d; }

.cart__item-price {
  margin-right: 6rem;
  font-weight: 900;
  font-size: 1.9rem;
  color: #193db9; }

.cart__item-close {
  display: block;
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  outline: none; }
  .cart__item-close:before, .cart__item-close:after {
    content: ' ';
    height: 1.5rem;
    width: 1px;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -.5px;
    background-color: #102a83; }
  .cart__item-close:before {
    transform: rotate(45deg); }
  .cart__item-close:after {
    transform: rotate(-45deg); }

.cart__total-price {
  margin-bottom: 5rem;
  color: #23304d;
  font-weight: 900;
  font-size: 1.9rem; }

.cart__btn {
  display: block;
  max-width: max-content;
  margin: 0 auto; }

@media (max-width: 1299px) {
  .cart__title {
    font-size: 2.2rem; }
  .cart__item-price, .cart__item-title {
    font-size: 1.8rem; } }

@media (max-width: 639px) {
  .cart__item-price {
    margin-right: 3rem; }
  .cart__total-price {
    margin-bottom: 3rem;
    font-size: 1.8rem; }
  .cart__title {
    margin-bottom: 3rem; } }

@media (max-width: 529px) {
  .cart__item {
    padding-bottom: 2rem;
    flex-direction: column; }
    .cart__item:last-of-type {
      padding-bottom: 0; }
  .cart__item + .cart__item {
    padding-top: 2rem; }
  .cart__item-col:first-of-type {
    margin-bottom: 1rem; }
  .cart__item-price {
    margin-right: 5rem; }
  .cart__items-overflow {
    padding: 0 1rem 0 0; }
  .cart {
    padding: 3rem 1rem 3rem 2rem; } }

@media (max-width: 429px) {
  .cart__title {
    font-size: 2rem; }
  .cart__item-price, .cart__item-title {
    font-size: 1.6rem; }
  .cart__total-price {
    font-size: 1.6rem; } }

.about {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: 60rem 1fr;
  grid-column-gap: 6.5rem; }
  .about--mod {
    grid-column-gap: 3rem;
    grid-template-columns: 63rem 1fr; }

.about__item-img-wrapper {
  margin-top: -15rem;
  position: relative; }
  .about__item-img-wrapper span {
    position: absolute;
    font-weight: 800;
    font-size: 3.5rem; }
  .about__item-img-wrapper span:first-of-type {
    bottom: 9%;
    left: 6%;
    color: #ffffff; }
  .about__item-img-wrapper span:last-of-type {
    color: #102a83;
    right: 6%;
    top: -2%; }
  .about__item-img-wrapper--mod {
    margin: 0; }

.about__title {
  margin-bottom: 4rem;
  font-weight: 700;
  font-size: 1.9rem;
  color: #23304d; }

.about__text {
  margin-bottom: 3rem; }

.about__link {
  padding-bottom: .9rem;
  font-size: 1.9rem;
  font-weight: 500;
  position: relative; }
  .about__link:after {
    content: '';
    display: block;
    width: 40%;
    height: 3px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #bf334d;
    transition: width .3s ease-out; }
  .about__link:hover:after {
    width: 100%; }
  .about__link span {
    margin-left: .5rem;
    font-family: 'Roboto', sans-serif;
    font-size: 1.6rem;
    font-weight: 400; }

.about__text-overflow {
  height: 32rem;
  overflow-y: auto; }

.about__text-overflow::-webkit-scrollbar {
  background-color: #f0f0f0;
  width: 1rem; }

.about__text-overflow::-webkit-scrollbar-thumb {
  background-color: #102a83; }

.about__text-overflow div {
  padding: .5rem 1.5rem 0  0;
  font-size: 1.6rem; }

.about__text-overflow p {
  margin: 0 0 2rem 0; }
  .about__text-overflow p:last-of-type {
    margin-bottom: 0; }

@media (max-width: 1639px) {
  .about__item-img-wrapper span {
    font-size: 3rem; } }

@media (max-width: 1499px) {
  .about__item-img-wrapper {
    margin: 0; }
    .about__item-img-wrapper span {
      font-size: 2.5rem; }
  .about {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem; }
    .about--mod {
      grid-column-gap: 3rem;
      grid-template-columns: 55rem 1fr; } }

@media (max-width: 1379px) {
  .about__item-img-wrapper span {
    font-size: 2.2rem; }
  .about {
    grid-column-gap: 0; } }

@media (max-width: 1199px) {
  .about {
    padding-left: 0; } }

@media (max-width: 1159px) {
  .about {
    grid-template-columns: 1fr;
    grid-row-gap: 5rem; }
  .about__item-img-wrapper span {
    font-size: 3rem; } }

@media (max-width: 819px) {
  .about__item-img-wrapper span {
    font-size: 2.5rem; }
  .about__text-overflow::-webkit-scrollbar {
    width: .5rem; } }

@media (max-width: 679px) {
  .about__item-img-wrapper span {
    font-size: 2rem; }
  .about__text-overflow div {
    padding: .5rem 1rem 0  0; } }

@media (max-width: 559px) {
  .about__item-img-wrapper span {
    font-size: 1.8rem; }
  .about__item-img-wrapper span:first-of-type {
    left: 5%; } }

@media (max-width: 493px) {
  .about__item-img-wrapper span {
    font-size: 1.6rem; } }

@media (max-width: 429px) {
  .about__item-img-wrapper span {
    display: none; } }

.section--about {
  padding-top: 30rem;
  margin-bottom: 17rem;
  padding-left: 5rem; }
  .section--about-another {
    padding-top: 0;
    padding-left: 0; }

.section--works {
  margin-bottom: 15rem;
  position: relative; }
  .section--works:before {
    content: '';
    display: block;
    width: 564px;
    height: 564px;
    background: url("../img/circle.svg") center no-repeat;
    background-size: contain;
    position: absolute;
    z-index: -2;
    bottom: 0;
    left: 0; }
    @media (max-width: 1599px) {
      .section--works:before {
        background-size: 80%; } }
    @media (max-width: 1399px) {
      .section--works:before {
        background-size: 60%; } }
    @media (max-width: 1023px) {
      .section--works:before {
        display: none; } }
  .section--works:after {
    content: '';
    display: block;
    width: 636px;
    height: 636px;
    background: url("../img/rectangle.svg") center no-repeat;
    background-size: contain;
    position: absolute;
    z-index: -2;
    top: 26rem;
    right: 0; }
    @media (max-width: 1599px) {
      .section--works:after {
        background-size: 80%; } }
    @media (max-width: 1399px) {
      .section--works:after {
        background-size: 60%; } }
    @media (max-width: 1023px) {
      .section--works:after {
        display: none; } }

.section--services {
  margin-bottom: 11rem;
  position: relative; }
  .section--services:before {
    content: '';
    display: block;
    width: 660px;
    height: 660px;
    background: url("../../img/services-circle-big.svg") center no-repeat;
    background-size: contain;
    position: absolute;
    bottom: -6%;
    left: -16%;
    z-index: -2; }
  .section--services:after {
    content: '';
    display: block;
    width: 321px;
    height: 307px;
    background: url("../../img/services-decor-grid.png") center no-repeat;
    background-size: contain;
    position: absolute;
    bottom: -13%;
    right: 0;
    z-index: -2; }

.section--recommendation {
  margin-bottom: 18rem; }

.section--news {
  margin-bottom: 10rem;
  position: relative; }
  .section--news:before {
    content: '';
    display: block;
    width: 636px;
    height: 636px;
    background: url("../img/rectangle.svg") center no-repeat;
    background-size: contain;
    position: absolute;
    z-index: -2;
    left: -5%;
    top: 0; }
    @media (max-width: 1599px) {
      .section--news:before {
        background-size: 80%; } }
    @media (max-width: 1399px) {
      .section--news:before {
        background-size: 60%; } }
    @media (max-width: 1023px) {
      .section--news:before {
        display: none; } }

.section--question {
  margin-bottom: 26rem;
  background: url("../../img/question-bg.jpg") no-repeat center;
  background-size: cover;
  position: relative; }
  .section--question:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: .78;
    z-index: 0;
    background-color: #23304d; }

.section--photo-gallery {
  padding-left: 4rem;
  margin-bottom: 23.5rem; }

.section--our-services {
  position: relative; }
  .section--our-services:before {
    content: '';
    display: block;
    width: 564px;
    height: 564px;
    background: url("../img/circle.svg") center no-repeat;
    background-size: contain;
    position: absolute;
    z-index: -2;
    top: 30%;
    left: 0; }
    @media (max-width: 1599px) {
      .section--our-services:before {
        background-size: 80%; } }
    @media (max-width: 1399px) {
      .section--our-services:before {
        background-size: 60%; } }
    @media (max-width: 1023px) {
      .section--our-services:before {
        display: none; } }
  .section--our-services:after {
    content: '';
    display: block;
    width: 636px;
    height: 636px;
    background: url("../img/rectangle.svg") center no-repeat;
    background-size: contain;
    position: absolute;
    z-index: -2;
    top: 10%;
    right: 0; }
    @media (max-width: 1599px) {
      .section--our-services:after {
        background-size: 80%; } }
    @media (max-width: 1399px) {
      .section--our-services:after {
        background-size: 60%; } }
    @media (max-width: 1023px) {
      .section--our-services:after {
        display: none; } }

.section--our-services__text-overflow {
  margin-bottom: 8rem;
  position: relative; }
  .section--our-services__text-overflow:before {
    content: '';
    display: block;
    width: 564px;
    height: 564px;
    background: url("../img/circle.svg") center no-repeat;
    background-size: contain;
    position: absolute;
    z-index: -2;
    top: 0;
    left: 0; }
    @media (max-width: 1599px) {
      .section--our-services__text-overflow:before {
        background-size: 80%; } }
    @media (max-width: 1399px) {
      .section--our-services__text-overflow:before {
        background-size: 60%; } }
    @media (max-width: 1023px) {
      .section--our-services__text-overflow:before {
        display: none; } }
  .section--our-services__text-overflow:after {
    content: '';
    display: block;
    width: 636px;
    height: 636px;
    background: url("../img/rectangle.svg") center no-repeat;
    background-size: contain;
    position: absolute;
    z-index: -2;
    bottom: 0;
    right: 0; }
    @media (max-width: 1599px) {
      .section--our-services__text-overflow:after {
        background-size: 80%; } }
    @media (max-width: 1399px) {
      .section--our-services__text-overflow:after {
        background-size: 60%; } }
    @media (max-width: 1023px) {
      .section--our-services__text-overflow:after {
        display: none; } }

.section--our-news {
  margin-bottom: 8.6rem;
  position: relative; }
  .section--our-news:before {
    content: '';
    display: block;
    width: 564px;
    height: 564px;
    background: url("../img/circle.svg") center no-repeat;
    background-size: contain;
    position: absolute;
    z-index: -2;
    top: 30%;
    left: 0; }
    @media (max-width: 1599px) {
      .section--our-news:before {
        background-size: 80%; } }
    @media (max-width: 1399px) {
      .section--our-news:before {
        background-size: 60%; } }
    @media (max-width: 1023px) {
      .section--our-news:before {
        display: none; } }
  .section--our-news:after {
    content: '';
    display: block;
    width: 636px;
    height: 636px;
    background: url("../img/rectangle.svg") center no-repeat;
    background-size: contain;
    position: absolute;
    z-index: -2;
    top: 10%;
    right: 0; }
    @media (max-width: 1599px) {
      .section--our-news:after {
        background-size: 80%; } }
    @media (max-width: 1399px) {
      .section--our-news:after {
        background-size: 60%; } }
    @media (max-width: 1023px) {
      .section--our-news:after {
        display: none; } }

.section--tabs {
  position: relative; }
  .section--tabs:after {
    content: '';
    display: block;
    width: 321px;
    height: 307px;
    background: url("../../img/services-decor-grid.png") center no-repeat;
    background-size: contain;
    position: absolute;
    bottom: -20%;
    right: 0;
    z-index: -2; }

.section--order {
  margin-bottom: 9rem;
  position: relative; }
  .section--order:before {
    content: '';
    display: block;
    width: 564px;
    height: 564px;
    background: url("../img/circle.svg") center no-repeat;
    background-size: contain;
    position: absolute;
    z-index: -2;
    bottom: 20%;
    left: 0; }
    @media (max-width: 1599px) {
      .section--order:before {
        background-size: 80%; } }
    @media (max-width: 1399px) {
      .section--order:before {
        background-size: 60%; } }
    @media (max-width: 1023px) {
      .section--order:before {
        display: none; } }
  .section--order:after {
    content: '';
    display: block;
    width: 636px;
    height: 636px;
    background: url("../img/rectangle.svg") center no-repeat;
    background-size: contain;
    position: absolute;
    z-index: -2;
    top: 20%;
    right: 0; }
    @media (max-width: 1599px) {
      .section--order:after {
        background-size: 80%; } }
    @media (max-width: 1399px) {
      .section--order:after {
        background-size: 60%; } }
    @media (max-width: 1023px) {
      .section--order:after {
        display: none; } }

.section--thanks {
  margin-bottom: 9rem;
  position: relative; }
  .section--thanks:after {
    content: '';
    display: block;
    width: 636px;
    height: 636px;
    background: url("../img/rectangle.svg") center no-repeat;
    background-size: contain;
    position: absolute;
    z-index: -2;
    top: 20%;
    right: 0; }
    @media (max-width: 1599px) {
      .section--thanks:after {
        background-size: 80%; } }
    @media (max-width: 1399px) {
      .section--thanks:after {
        background-size: 60%; } }
    @media (max-width: 1023px) {
      .section--thanks:after {
        display: none; } }

@media (max-width: 1599px) {
  .section--works:before {
    background-position: left center;
    left: -10%;
    bottom: -5%; }
  .section--works:after {
    background-position: right center;
    right: -10%;
    top: 10%; }
  .section--services:before {
    background-size: 85%;
    bottom: -12%;
    left: -20%; }
  .section--services:after {
    background-size: 80%;
    bottom: -13%;
    right: -5%; }
  .section--news:before {
    left: -10%;
    top: 5%; }
  .section--our-services:before {
    top: 25%;
    left: -5%; }
  .section--our-services:after {
    top: 5%;
    right: -5%; }
  .section--our-services__text-overflow:before {
    top: -20%;
    left: -5%; }
  .section--our-services__text-overflow:after {
    bottom: -20%;
    right: -5%; }
  .section--our-news:before {
    top: 25%;
    left: -5%; }
  .section--our-news:after {
    top: 5%;
    right: -5%; }
  .section--tabs:after {
    background-size: 80%;
    bottom: -20%;
    right: -5%; }
  .section--order:before {
    bottom: 20%;
    left: -10%; }
  .section--order:after {
    top: 20%;
    right: -10%; }
  .section--thanks:after {
    top: 15%;
    right: -10%; } }

@media (max-width: 1499px) {
  .section--services:before {
    background-size: 80%;
    bottom: -17%;
    left: -22%; } }

@media (max-width: 1399px) {
  .section--news:before {
    left: -15%;
    top: 15%; }
  .section--our-services__text-overflow:before {
    top: -45%;
    left: -15%; }
  .section--our-services__text-overflow:after {
    bottom: -55%;
    right: -15%; }
  .section--our-services:before {
    top: 20%;
    left: -15%; }
  .section--our-services:after {
    top: 1%;
    right: -15%; }
  .section--our-news:before {
    top: 20%;
    left: -15%; }
  .section--our-news:after {
    top: -4%;
    right: -15%; }
  .section--order:before {
    bottom: 10%;
    left: -15%; }
  .section--order:after {
    top: 10%;
    right: -15%; }
  .section--thanks:after {
    top: 10%;
    right: -15%; } }

@media (max-width: 1299px) {
  .section--services:before {
    background-size: 70%;
    bottom: -21%;
    left: -25%; } }

@media (max-width: 1199px) {
  .section--about {
    padding-left: 0; }
  .section--services:before {
    display: none; }
  .section--services:after {
    background-size: 70%;
    bottom: -15%;
    right: -10%; }
  .section--news:before {
    left: -20%;
    top: 20%; }
  .section--photo-gallery {
    padding: 0; }
  .section--our-services__text-overflow:before {
    left: -20%; }
  .section--our-services__text-overflow:after {
    right: -20%; }
  .section--our-services:before {
    left: -20%; }
  .section--our-services:after {
    right: -20%; }
  .section--tabs:after {
    background-size: 70%;
    bottom: -20%;
    right: -10%; } }

@media (max-width: 991px) {
  .section--about {
    padding-top: 15rem; }
    .section--about-another {
      padding-top: 0;
      padding-left: 0; } }

@media (max-width: 767px) {
  .section--services:after {
    display: none; }
  .section--photo-gallery {
    margin-bottom: 15rem; }
  .section--question {
    margin-bottom: 15rem; }
  .section--tabs:after {
    display: none; } }

.title {
  font-size: 3.3rem;
  color: #102a83;
  font-weight: 700;
  position: relative;
  text-align: center; }

.title--main {
  font-size: 4.4rem;
  text-transform: uppercase;
  text-align: left; }

.title--margin {
  margin-left: 0; }
  @media (max-width: 1899px) {
    .title--margin {
      margin-left: 5rem; } }
  @media (max-width: 1199px) {
    .title--margin {
      margin-left: 0; } }

.title__border {
  padding: 0 0 .3rem 0;
  position: relative; }
  .title__border:after {
    content: '';
    width: 70%;
    height: 6px;
    background-color: #bf334d;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1; }

.title__sub {
  font-family: 'Futura PT Extra', sans-serif;
  font-weight: 800;
  font-size: 29.3rem;
  text-transform: lowercase;
  line-height: 1.1;
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #dddddd;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -45%);
  z-index: -1; }

.title__sub--main {
  left: -2%;
  transform: none;
  bottom: -100%;
  top: auto;
  text-transform: uppercase; }

@media (max-width: 1699px) {
  .title__sub {
    font-size: 25rem; } }

@media (max-width: 1399px) {
  .title__sub {
    font-size: 22rem; }
  .title--main {
    font-size: 4rem; } }

@media (max-width: 1299px) {
  .title__sub {
    font-size: 20rem; }
  .title {
    font-size: 3rem; }
  .title__border {
    padding: 0 0 1px 0; }
    .title__border:after {
      height: 3px; } }

@media (max-width: 1199px) {
  .title__sub {
    font-size: 18rem; } }

@media (max-width: 991px) {
  .title__sub {
    font-size: 14rem; }
  .title {
    font-size: 2.8rem; } }

@media (max-width: 767px) {
  .title__sub {
    display: none; } }

@media (max-width: 575px) {
  .title {
    font-size: 2.5rem; } }

@media (max-width: 369px) {
  .title {
    font-size: 2.2rem; } }

.works {
  margin: 8rem 0 0; }

.works__container {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto; }

.works__item {
  margin-bottom: 3rem;
  display: block;
  width: 100%;
  min-height: 24.5rem;
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;
  text-decoration: none;
  color: #ffffff;
  cursor: pointer;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  transition: box-shadow .3s ease-in-out; }
  .works__item:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0.6;
    z-index: 1;
    transition: opacity .4s;
    background-color: #102a83; }

.works__item-content {
  z-index: 2;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: calc(100% - 4rem);
  transition: all .4s;
  padding: 0 2rem; }

.works__item-title {
  margin-bottom: 4rem;
  font-size: 2rem;
  font-weight: 700; }

.works__item-text {
  margin-bottom: 4rem;
  font-weight: 500; }

.works__item-link {
  width: max-content;
  padding-bottom: .5rem;
  font-size: 1.6rem;
  font-weight: 500;
  position: relative; }
  .works__item-link:after {
    content: '';
    display: block;
    width: 40%;
    height: 3px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #bf334d;
    transition: width 1s ease-in-out; }
  .works__item-link span {
    font-family: 'Roboto', sans-serif;
    font-size: 1.4rem;
    font-weight: 400; }

/* works__item:hover */
.works__item:hover {
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.16); }

.works__item:hover:after {
  opacity: 0.75; }

.works__item:hover .works__item-content {
  top: 3rem; }

.works__item:hover .works__item-link:after {
  width: 100%; }

@media (max-width: 1299px) {
  .works__container {
    max-width: 100%; } }

@media (max-width: 699px) {
  .works__item-title {
    margin-bottom: 3rem;
    font-size: 1.8rem; }
  .works__item-content {
    padding: 0 1.5rem; }
  .works__item-text {
    margin-bottom: 3rem; } }

@media (max-width: 575px) {
  .works__item-title {
    margin-bottom: 4rem; }
  .works__item-text {
    margin-bottom: 4rem; } }

.services {
  margin-top: 9rem;
  padding: 15rem 19rem 15rem 18rem;
  width: 100%;
  background-color: #f8faff;
  box-shadow: 0 25px 60px rgba(0, 0, 0, 0.16); }

.services__inner {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 9rem;
  grid-column-gap: 5rem; }

.services__item {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 2.5rem;
  align-items: flex-start; }

.services__item-img-wrap {
  width: 8.7rem;
  height: 8.7rem;
  background-color: #f7f7f7;
  border: 2px solid #102a83;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center; }

.services__item-content {
  margin-top: 1rem; }

.services__item-title {
  display: block;
  margin-bottom: 1.2rem;
  font-weight: 700;
  color: #102a83;
  font-size: 1.6rem; }

.services__item-text {
  font-weight: 500;
  color: #8e8e8e; }

@media (max-width: 1699px) {
  .services {
    padding: 15rem 15rem 15rem 14rem; } }

@media (max-width: 1599px) {
  .services {
    padding: 12rem 10rem 12rem 9rem; } }

@media (max-width: 1499px) {
  .services {
    padding: 10rem 7rem; }
  .services__inner {
    grid-row-gap: 8rem;
    grid-column-gap: 4rem; } }

@media (max-width: 1299px) {
  .services {
    padding: 8rem 5rem; } }

@media (max-width: 1199px) {
  .services__inner {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 4rem; }
  .services {
    padding: 8rem 4rem; }
  .services__item-img-wrap {
    width: 8rem;
    height: 8rem; } }

@media (max-width: 767px) {
  .services__inner {
    grid-template-columns: 1fr;
    grid-row-gap: 3rem; }
  .services__item {
    grid-column-gap: 2rem; } }

@media (max-width: 575px) {
  .services {
    padding: 4rem 1.5rem; }
  .services__item-img-wrap {
    width: 7rem;
    height: 7rem; }
    .services__item-img-wrap img {
      width: 5.2rem;
      height: 5.2rem; } }

.card {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 34.6rem;
  margin: 0 auto;
  background-color: #f7f7f7; }

.card__header {
  background-color: #161c2d;
  padding-bottom: 81%;
  position: relative; }
  .card__header:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    opacity: .3;
    z-index: 0;
    background-color: #23304d; }

.card__header img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0; }

.card__header-price {
  background-color: #102a83;
  color: #ffffff;
  font-size: 2rem;
  font-weight: 700;
  padding: 1rem 2rem;
  position: absolute;
  left: 0;
  bottom: 10%;
  z-index: 1; }

.card__content {
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 4rem 2.4rem 4rem 1.4rem; }

.card__title {
  font-size: 2rem;
  color: #2c2c2c;
  font-weight: 700; }

.card__btn {
  margin: 1rem 0 0 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 3.5rem;
  height: 3.5rem;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer; }

@media (max-width: 1399px) {
  .card__title {
    font-size: 1.8rem; }
  .card__btn {
    margin: 1rem 0 0 2rem;
    width: 3rem;
    height: 3rem; }
    .card__btn svg {
      width: 3rem;
      height: 3rem; }
  .card__header-price {
    font-size: 1.8rem;
    padding: 1rem 1.8rem; } }

@media (max-width: 1299px) {
  .card__content {
    padding: 3.5rem 1.5rem; }
  .card__btn {
    margin: 1rem 0 0 1.5rem;
    width: 2.5rem;
    height: 2.5rem; }
    .card__btn svg {
      width: 2.5rem;
      height: 2.5rem; }
  .card__header-price {
    padding: 1rem 1.5rem; } }

@media (max-width: 991px) {
  .card__content {
    padding: 3rem 1.5rem; }
  .card__btn {
    margin: 1rem 0 0 1.5rem;
    width: 2.2rem;
    height: 2.2rem; }
    .card__btn svg {
      width: 2.2rem;
      height: 2.2rem; }
  .card__title {
    font-size: 1.6rem; }
  .card__header-price {
    padding: 1rem 1.5rem;
    font-size: 1.6rem; } }

@media (max-width: 767px) {
  .card__btn {
    margin: 1rem 0 0 3rem;
    width: 2.2rem;
    height: 2.2rem; }
    .card__btn svg {
      width: 2.2rem;
      height: 2.2rem; } }

@media (max-width: 575px) {
  .card__content {
    padding: 3.5rem 2rem; } }

.recommendation {
  margin-top: 7rem;
  padding-left: 4.5rem;
  padding-right: 15rem; }

.recommendation__banner {
  display: block;
  height: 100%;
  width: 36rem;
  max-width: 100%;
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;
  text-decoration: none;
  color: #ffffff;
  cursor: pointer; }
  .recommendation__banner:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0.6;
    z-index: 1;
    background-color: #102a83;
    transition: opacity .4s linear; }

.recommendation__banner-content {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
  padding: 10rem 2rem 2rem 3rem; }

.recommendation__banner-title {
  font-weight: 700;
  font-size: 3.5rem;
  line-height: 1.1; }

.recommendation__banner-link {
  width: max-content;
  padding-bottom: .5rem;
  font-size: 1.8rem;
  font-weight: 500;
  position: absolute;
  bottom: 5%;
  right: 9%; }
  .recommendation__banner-link:after {
    content: '';
    display: block;
    width: 40%;
    height: 3px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #bf334d;
    transition: width .4s linear; }
  .recommendation__banner-link span {
    margin-left: .5rem;
    font-family: 'Roboto', sans-serif;
    font-size: 1.6rem;
    font-weight: 400; }

.recommendation__banner:hover .recommendation__banner-link:after {
  width: 100%; }

.recommendation__banner:hover:after {
  opacity: 0.8; }

@media (max-width: 1499px) {
  .recommendation {
    padding: 0 4rem; } }

@media (max-width: 1399px) {
  .recommendation__banner-content {
    padding: 7rem 2rem 2rem; }
  .recommendation__banner-title {
    font-size: 3rem; } }

@media (max-width: 1299px) {
  .recommendation {
    padding: 0 3rem 0 2rem; }
  .recommendation__banner-title {
    font-size: 2.8rem; }
  .recommendation__banner-link {
    font-size: 1.6rem; }
    .recommendation__banner-link span {
      font-size: 1.4rem;
      margin-left: 0; } }

@media (max-width: 1199px) {
  .recommendation {
    padding: 0 1rem; }
  .recommendation__banner-content {
    padding: 5rem 1.5rem 2rem; } }

@media (max-width: 991px) {
  .recommendation__banner-title {
    font-size: 2.5rem; }
  .recommendation {
    padding: 0; } }

@media (max-width: 575px) {
  .recommendation__banner {
    height: 30rem;
    max-height: 100%;
    margin: 3rem auto 0; } }

.slide-two .owl-dots {
  position: absolute;
  bottom: -5rem;
  left: 50%;
  transform: translateX(-50%); }

.slide-two .owl-dot {
  background-color: rgba(116, 138, 213, 0.54) !important;
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  margin: 0 .9rem;
  outline: none; }

.slide-two .owl-dot.active {
  background-color: #193db9 !important; }

@media (max-width: 1299px) {
  .slide-two .owl-dot {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 .9rem; }
  .slide-two .owl-dots {
    bottom: -3rem; } }

.news {
  margin-top: 8.5rem; }

.news__inner {
  margin-bottom: 4rem;
  display: grid;
  grid-template-columns: 57rem 57rem;
  grid-column-gap: 2.5rem;
  justify-content: center; }

.news__item {
  padding: 3rem 6.5rem 5rem 2.5rem;
  background-color: #fdfdfd;
  border: 1px solid #e2e2e2;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }

.news__item-header {
  margin-bottom: 2.9rem;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 1.6rem;
  align-items: center; }

.news__item-img__wrapper {
  border: 1px solid #23304d;
  background-color: #f7f7f7;
  border-radius: 50%;
  width: 5.8rem;
  height: 5.8rem;
  display: flex;
  align-items: center;
  justify-content: center; }

.news__item-title {
  font-size: 1.4rem;
  font-weight: 700;
  color: #23304d; }

.news__btn {
  display: block;
  width: max-content;
  margin: 0 auto; }
  .news__btn span {
    margin-left: .5rem;
    font-family: 'Roboto', sans-serif;
    font-size: 1.6rem; }

@media (max-width: 1299px) {
  .news__inner {
    grid-template-columns: 1fr 1fr; }
  .news__item {
    padding: 3rem 5rem 5rem 2.5rem;
    background-color: #fdfdfd;
    border: 1px solid #e2e2e2;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); } }

@media (max-width: 1199px) {
  .news__item {
    padding: 3rem 2rem 4rem; } }

@media (max-width: 991px) {
  .news__item {
    padding: 3rem 1rem 4rem 2rem; }
  .news__inner {
    grid-column-gap: 2rem; } }

@media (max-width: 767px) {
  .news__inner {
    grid-template-columns: 1fr;
    grid-row-gap: 3rem; }
  .news__item {
    padding: 3rem 2rem 4rem; } }

@media (max-width: 575px) {
  .news__item {
    padding: 3rem 1.5rem 4rem 2rem; } }

.footer {
  background-color: #102a83;
  position: relative; }

.footer__inner {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.footer__left {
  overflow: hidden;
  padding: 3rem 0 6rem 3rem;
  position: relative; }
  .footer__left:after {
    content: '';
    display: block;
    width: 564px;
    height: 564px;
    background: url("../img/footer-left-icon.svg") center no-repeat;
    background-size: contain;
    position: absolute;
    bottom: -25%;
    right: -20%;
    z-index: 0;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none; }

.footer-nav__wrap {
  background-color: #102a83; }

.footer-nav {
  height: 9.4rem;
  display: flex;
  align-items: center;
  justify-content: center; }

.footer-nav__list {
  display: flex;
  justify-content: space-between; }

.footer-nav__item + .footer-nav__item {
  margin-left: 4.5rem; }

.footer-nav__link {
  font-size: 2.2rem;
  color: #ffffff;
  font-weight: 900; }

@media (max-width: 1499px) {
  .footer__left:after {
    background-size: 80%;
    bottom: -35%;
    right: -25%; }
  .footer-nav__link {
    font-size: 2rem; }
  .footer-nav {
    height: 9rem; } }

@media (max-width: 1299px) {
  .footer__left:after {
    background-size: 60%;
    bottom: -40%;
    right: -35%; }
  .footer-nav__link {
    font-size: 1.9rem; }
  .footer-nav__item + .footer-nav__item {
    margin-left: 4rem; } }

@media (max-width: 1199px) {
  .footer-nav__item + .footer-nav__item {
    margin-left: 3.5rem; }
  .footer__left {
    padding-left: 1rem; } }

@media (max-width: 1099px) {
  .footer-nav__list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2rem; }
  .footer-nav__item + .footer-nav__item {
    margin: 0; }
  .footer-nav {
    padding: 3rem 0 3rem 1rem;
    height: auto;
    display: block; } }

@media (max-width: 991px) {
  .footer__inner {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 40rem; }
  .footer__left:after {
    background-size: 100%;
    bottom: -25%;
    right: -20%; }
  .footer-nav__link {
    font-size: 1.8rem; } }

@media (max-width: 899px) {
  .footer__left:after {
    background-size: 80%;
    bottom: -35%;
    right: -25%; }
  .footer-nav__list {
    grid-template-columns: 1fr 1fr;
    grid-gap: 0;
    grid-row-gap: 2rem; } }

@media (max-width: 699px) {
  .footer__left:after {
    background-size: 60%;
    bottom: -40%;
    right: -35%; } }

@media (max-width: 575px) {
  .footer-nav__link {
    font-size: 1.6rem; }
  .footer__left:after {
    display: none; } }

@media (max-width: 499px) {
  .footer__left {
    padding-left: 0; }
  .footer-nav {
    padding-left: 0; } }

@media (max-width: 439px) {
  .footer-nav__list {
    grid-template-columns: 1fr;
    grid-row-gap: 1rem; }
  .footer-nav {
    padding: 2rem 0; } }

.contact-us {
  position: relative;
  z-index: 1;
  color: #ffffff; }
  .contact-us svg {
    fill: #ffffff; }
  .contact-us a {
    display: flex;
    align-items: center;
    font-size: 1.9rem;
    font-weight: 500; }
    .contact-us a svg {
      margin-right: .9rem; }

.contact-us--blue {
  color: #23304d; }
  .contact-us--blue svg {
    fill: #23304d; }
  .contact-us--blue .contact-us__subtitle {
    color: #102a83; }
  .contact-us--blue .contact-us__title {
    color: #102a83; }

.contact-us__subtitle {
  margin-bottom: 1rem;
  color: #afafaf;
  font-size: 1.6rem;
  font-weight: 400; }
  .contact-us__subtitle.first {
    margin-bottom: 0; }

.contact-us__title {
  margin-bottom: 3rem;
  font-size: 3.3rem;
  font-weight: 700; }

.contact-us__address {
  margin-bottom: 3rem;
  max-width: 32rem;
  font-style: normal;
  font-size: 1.9rem;
  font-weight: 500;
  display: grid;
  grid-template-columns: auto auto;
  align-items: flex-start; }
  .contact-us__address svg {
    margin-top: .5rem;
    margin-right: 1.5rem; }

.contact-us__phone-lists {
  margin-bottom: 3rem;
  display: grid;
  grid-template-columns: max-content max-content;
  grid-column-gap: 5rem; }
  .contact-us__phone-lists li {
    margin-bottom: .5rem; }
    .contact-us__phone-lists li:last-of-type {
      margin-bottom: 0; }

.contact-us__mail-list {
  margin-bottom: 3rem; }
  .contact-us__mail-list li {
    margin-bottom: 1rem; }
    .contact-us__mail-list li:last-of-type {
      margin-bottom: 0; }

.contact-us__link {
  padding: 1.5rem 3.5rem;
  display: block;
  width: max-content;
  background-color: #ffffff;
  color: #102a83;
  font-size: 1.9rem;
  font-weight: 400;
  transition: background-color .2s linear; }
  .contact-us__link:hover {
    background-color: #f2f2f2; }

@media (max-width: 1499px) {
  .contact-us a {
    font-size: 1.8rem; }
  .contact-us__address {
    font-size: 1.8rem;
    max-width: 30rem; }
  .contact-us__title {
    font-size: 3.2rem; } }

@media (max-width: 1199px) {
  .contact-us a {
    font-size: 1.7rem; }
  .contact-us__address {
    font-size: 1.7rem; }
  .contact-us__title {
    font-size: 3rem; }
  .contact-us__subtitle {
    font-size: 1.5rem; } }

@media (max-width: 899px) {
  .contact-us--blue {
    margin: 0 auto;
    width: max-content; } }

@media (max-width: 575px) {
  .contact-us a {
    font-size: 1.6rem; }
  .contact-us__address {
    font-size: 1.6rem; }
  .contact-us__title {
    font-size: 2.8rem; }
  .contact-us__subtitle {
    font-size: 1.4rem; }
  .contact-us__link {
    padding: 1.4rem 3rem; }
  .contact-us--blue {
    margin: 0;
    width: 100%; } }

@media (max-width: 449px) {
  .contact-us__phone-lists {
    grid-template-columns: max-content;
    grid-row-gap: 2rem; }
    .contact-us__phone-lists li {
      margin-bottom: 1rem; }
      .contact-us__phone-lists li:last-of-type {
        margin-bottom: 0; }
  .contact-us__title {
    font-size: 2.5rem; } }

.copyright {
  background-color: #0c1e5c;
  color: #ffffff;
  height: 3rem;
  display: flex;
  align-items: center; }

.copyright__inner {
  width: 100%;
  display: flex;
  justify-content: space-between; }

.copyright__text, .copyright__link {
  font-size: 1.4rem;
  font-weight: 400; }

@media (max-width: 575px) {
  .copyright {
    margin-bottom: 4.4rem; } }

@media (max-width: 529px) {
  .copyright {
    height: auto;
    display: block; }
  .copyright__inner {
    padding: 1rem 0;
    display: block; }
  .copyright__text {
    margin-bottom: .5rem; } }

.pipe-table__container {
  width: 90%;
  margin: 20px auto;
  padding: 50px 20px 20px;
  overflow: auto; }

@media screen and (max-width: 1100px) {
  .pipe-table__container {
    width: auto;
    margin: 20px;
    padding: 50px 0 0; } }

.pipe-table__container:not(:last-child) {
  margin-bottom: 20px; }

.pipe-grid,
.pipe-table {
  border: 1px solid #000; }

.pipe-table {
  margin-bottom: 20px;
  min-width: 850px; }

.pipe-table__body,
.pipe-table__cell,
.pipe-table__head,
.pipe-table__row {
  border-style: solid;
  border-color: #000; }

.pipe-table__head {
  flex-basis: 100%;
  font-weight: 700;
  padding: 5px; }

.pipe-table__body {
  display: flex;
  flex-basis: 100%; }

.pipe-table__text-center {
  justify-content: center; }

.pipe-table__text-right {
  justify-content: flex-end; }

.pipe-table__body-textual {
  display: flex;
  flex-direction: column;
  flex-basis: 75%; }

.pipe-table__row {
  display: flex;
  flex-grow: 1; }

.pipe-table__cell {
  display: flex;
  flex-basis: 33.33%;
  align-items: center;
  padding: 5px;
  border-top: 1px solid #000; }

.pipe-table__cell {
  border-right: 1px solid #000; }

.pipe-table__img-container {
  display: flex;
  align-items: center;
  flex-basis: 25%;
  border-top: 1px solid #000; }

.pipe-table__img-container img {
  width: 300px; }

.pipe-grid {
  border: 1px solid #000;
  min-width: 850px;
  margin-bottom: 20px; }

.pipe-grid__head {
  display: flex;
  border-bottom: 1px solid #000; }

.pipe-grid__head-number-text {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-basis: 10%;
  border-right: 1px solid #000; }

.pipe-grid__wall-sizes {
  flex-basis: 90%; }

.wall-sizes__title {
  padding: 5px;
  text-align: center;
  border-bottom: 1px solid #000; }

.wall-sizes__values {
  display: flex; }

.wall-sizes__value {
  flex-basis: 7.7%;
  padding: 5px;
  text-align: center; }

.wall-sizes__value:not(:last-child) {
  border-right: 1px solid #000; }

.pipe-grid__body-row {
  display: flex; }

.pipe-grid__body-row:not(:last-child) {
  border-bottom: 1px solid #000; }

.pipe-grid__body-size {
  flex-basis: 10%;
  text-align: center;
  border-right: 1px solid #000; }

.pipe-grid__body-cell {
  display: flex;
  flex-grow: 1;
  flex-basis: 6.9%;
  align-items: center;
  padding: 5px; }

.pipe-grid__body-cell:not(:last-child) {
  border-right: 1px solid #000; }

.pipe-grid__body-cell-marker {
  width: 15px;
  height: 15px;
  background: #000;
  border-radius: 50%; }

@media screen and (max-width: 1100px) {
  .pipe-grid__body-cell-marker {
    width: 10px;
    height: 10px; } }

.breadcrumb {
  margin: 18.5rem 0 20rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  list-style: none; }
  .breadcrumb--low-margin {
    margin: 18.5rem 0 6rem; }
  @media (max-width: 1899px) {
    .breadcrumb {
      margin: 18.5rem 0 20rem 5rem; }
      .breadcrumb--low-margin {
        margin: 18.5rem 0 6rem 5rem; } }
  @media (max-width: 1199px) {
    .breadcrumb {
      margin: 18.5rem 0 20rem 3rem; }
      .breadcrumb--low-margin {
        margin: 18.5rem 0 6rem 3rem; } }
  @media (max-width: 767px) {
    .breadcrumb {
      margin: 16rem 0 14rem 3rem; }
      .breadcrumb--low-margin {
        margin: 16rem 0 6rem 3rem; } }
  @media (max-width: 575px) {
    .breadcrumb {
      margin: 16rem 0 8rem; }
      .breadcrumb--low-margin {
        margin: 16rem 0 6rem; } }

.breadcrumb-item {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.4rem;
  color: #6a6a6a; }
  .breadcrumb-item a {
    color: #102a83; }

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  margin-right: 1rem;
  content: '';
  width: 12px;
  height: 6px;
  background: url("../img/breadcrumb-arrow.svg") no-repeat center;
  background-size: cover; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 1rem; }

.question {
  padding: 4.4rem 0 4.4rem 9.4rem;
  position: relative; }
  .question:after {
    content: '';
    display: block;
    width: 58.8rem;
    height: 54rem;
    background: url("../img/question-img.png") no-repeat;
    position: absolute;
    right: 9%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1; }

.question__form {
  padding: 6rem 7rem 4rem;
  width: 55rem;
  background-color: #ffffff;
  box-shadow: 0 3px 60px rgba(0, 0, 0, 0.16);
  position: relative;
  z-index: 1; }

.question__form-inner {
  margin-bottom: 4rem;
  display: grid;
  grid-template-rows: 6.4rem 6.4rem 6.4rem 9.9rem;
  grid-row-gap: 2.5rem; }

.question__form-title {
  margin-bottom: 3.5rem;
  font-size: 2.5rem;
  text-align: center;
  text-transform: uppercase; }

.question__form-input,
.question__form-textarea {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-size: 1.9rem;
  font-weight: 400;
  color: #000000;
  outline: 0;
  transition: border .2s linear;
  padding: 0 1.6rem;
  background: none;
  border: 1px solid #707070;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }
  .question__form-input::placeholder,
  .question__form-textarea::placeholder {
    color: #6a6a6a; }
  .question__form-input:focus,
  .question__form-textarea:focus {
    border-color: #102a83; }

.question__form-textarea {
  resize: none;
  padding-top: 1rem; }

.question__form-btn {
  display: block;
  margin: 0 auto; }

@media (max-width: 1599px) {
  .question:after {
    right: 0;
    background-size: 90%; } }

@media (max-width: 1499px) {
  .question__form-inner {
    grid-template-rows: 6rem 6rem 6rem 9.5rem; }
  .question {
    padding: 4rem 0 4rem 9rem; } }

@media (max-width: 1399px) {
  .question__form-title {
    margin-bottom: 3rem;
    font-size: 2.2rem; }
  .question__form-inner {
    grid-template-rows: 5rem 5rem 5rem 8rem; }
  .question {
    padding: 3rem 0 3rem 3rem; }
    .question:after {
      right: -5%;
      top: 55%;
      background-size: 80%; }
  .question__form {
    padding: 4rem 5rem 3rem;
    width: 45rem; }
  .question__form-input,
  .question__form-textarea {
    font-size: 1.6rem; } }

@media (max-width: 1199px) {
  .question {
    padding: 3rem 0 3rem 2rem; }
    .question:after {
      right: -10%; } }

@media (max-width: 991px) {
  .question:after {
    right: -25%;
    top: 70%;
    background-size: 60%; } }

@media (max-width: 899px) {
  .question:after {
    right: -30%; } }

@media (max-width: 799px) {
  .question {
    padding: 3rem 0 3rem 2rem; }
    .question:after {
      right: -40%; } }

@media (max-width: 767px) {
  .question {
    padding: 3rem 0; }
    .question:after {
      display: none; }
  .question__form {
    margin: 0 auto; } }

@media (max-width: 575px) {
  .question__form {
    width: 100%; } }

@media (max-width: 499px) {
  .question__form-title {
    font-size: 2rem; }
  .question__form {
    padding: 3rem 2rem; }
  .question__form-inner {
    grid-template-rows: 4.5rem 4.5rem 4.5rem 7rem; } }

@media (max-width: 369px) {
  .question__form {
    padding: 3rem 1.5rem; } }

.photo-gallery {
  margin-top: 4.7rem;
  padding: 0 8rem 0 4rem;
  position: relative; }

.photo-gallery .slide-three .owl-nav button.owl-prev,
.photo-gallery .slide-three .owl-nav button.owl-next {
  outline: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1; }

.photo-gallery .slide-three .owl-nav button.owl-next {
  right: -3%; }

.photo-gallery .slide-three .owl-nav button.owl-prev {
  left: -3%; }

@media (max-width: 1499px) {
  .photo-gallery {
    padding: 0 4rem 0 0; }
  .photo-gallery .slide-three .owl-nav button.owl-next {
    right: -2%; }
  .photo-gallery .slide-three .owl-nav button.owl-prev {
    left: -2%; } }

@media (max-width: 1199px) {
  .photo-gallery {
    padding: 0 2rem; } }

@media (max-width: 991px) {
  .photo-gallery {
    padding: 0; } }

.our-services {
  margin: 10rem 0 0 0; }

.our-services__text {
  margin-bottom: 5rem; }

.our-services__list {
  margin-bottom: 8rem; }

.our-services__list-item + .our-services__list-item {
  margin-top: 8rem; }

.our-services__list-item a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 23px 44px 21px 22px;
  background-color: #f8faff;
  box-shadow: 0 3px 60px rgba(0, 0, 0, 0.16); }

.our-services__list-item img {
  margin-right: 133px;
  object-fit: contain; }

.our-services__list-item__title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #102a83; }

.our-services__list-item__left {
  display: flex;
  align-items: center; }

.our-services__text-overflow {
  height: 23rem;
  overflow-y: auto; }

.our-services__text-overflow::-webkit-scrollbar {
  background-color: #f0f0f0;
  width: 1rem; }

.our-services__text-overflow::-webkit-scrollbar-thumb {
  background-color: #102a83; }

.our-services__text-overflow div {
  padding: .5rem 1.5rem 0  0;
  font-size: 1.9rem; }

.our-services__text-overflow p {
  margin: 0 0 2rem 0; }
  .our-services__text-overflow p:last-of-type {
    margin-bottom: 0; }

@media (max-width: 1399px) {
  .our-services__list-item__title {
    font-size: 2.2rem; } }

@media (max-width: 1299px) {
  .our-services__list-item img {
    margin-right: 8rem;
    width: 12rem;
    height: 12rem; }
  .our-services__list-item svg {
    width: 32px;
    height: 11px; } }

@media (max-width: 1199px) {
  .our-services__list-item img {
    margin-right: 5rem;
    width: 10rem;
    height: 10rem; }
  .our-services__list-item__title {
    font-size: 2rem; } }

@media (max-width: 767px) {
  .our-services__text-overflow div {
    font-size: 1.8rem; } }

@media (max-width: 649px) {
  .our-services__list-item a {
    display: block; }
  .our-services__list-item img {
    margin-right: 2rem;
    width: 8rem;
    height: 8rem; }
  .our-services__list-item__left {
    margin-bottom: 2rem;
    justify-content: space-between; }
  .our-services__list-item svg {
    margin-left: 2rem; } }

@media (max-width: 575px) {
  .our-services__list-item__title {
    font-size: 1.8rem;
    text-align: center; }
  .our-services__list-item a {
    padding: 2rem; }
  .our-services__text {
    font-size: 1.8rem; }
  .our-services__text-overflow div {
    font-size: 1.7rem;
    padding: .5rem 1rem 0  0; } }

@media (max-width: 369px) {
  .our-services__list-item a {
    padding: 2rem 1.5rem 2rem 1rem; }
  .our-services__list-item img {
    margin-right: 1.5rem; } }

.our-news {
  margin-top: 9rem; }

.our-news__inner {
  margin-bottom: 5rem;
  display: grid;
  grid-template-columns: 57rem 57rem;
  grid-column-gap: 2.5rem;
  grid-row-gap: 3.3rem;
  justify-content: center; }

.our-news__btn {
  margin: 0 auto;
  display: block; }

@media (max-width: 1299px) {
  .our-news__inner {
    grid-template-columns: 1fr 1fr; } }

@media (max-width: 991px) {
  .our-news__inner {
    grid-column-gap: 2rem;
    grid-row-gap: 3rem; } }

@media (max-width: 767px) {
  .our-news__inner {
    grid-template-columns: 1fr; } }

.our-service {
  margin: 9rem 0 8rem; }

.our-service__intro {
  margin-bottom: 7rem;
  min-height: 47rem;
  background: url("../../img/our-service-bg.jpg") no-repeat center;
  background-size: cover;
  position: relative; }
  .our-service__intro:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: .78;
    z-index: 0;
    background-color: #23304d; }

.our-service__btn {
  position: absolute;
  right: 2%;
  bottom: 5%;
  z-index: 1;
  color: #ffffff;
  border: 2px solid #ffffff;
  font-size: 1.9rem;
  font-weight: 700;
  padding: 15px 53px 19px 55px; }

@media (max-width: 1199px) {
  .our-service__btn {
    right: 3%; } }

@media (max-width: 991px) {
  .our-service__btn {
    font-weight: 700;
    padding: 15px 40px; } }

@media (max-width: 767px) {
  .our-service__btn {
    font-size: 1.8rem; } }

@media (max-width: 575px) {
  .our-service__btn {
    padding: 10px 30px; } }

.news-page {
  margin: 9rem 0 11rem; }

.news-page__intro {
  margin-bottom: 7rem;
  min-height: 47rem;
  background: url("../../img/news-page-bg.jpg") no-repeat center;
  background-size: cover;
  position: relative; }
  .news-page__intro:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: .78;
    z-index: 0;
    background-color: #23304d; }

.news-page__intro time {
  padding: 11px 22px 11px 19px;
  background-color: #102a83;
  color: #ffffff;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 5%;
  left: 0;
  z-index: 1; }
  .news-page__intro time svg {
    margin-top: -3px;
    margin-right: 1.2rem; }

.news-page__text {
  margin-bottom: 4rem; }

.news-page__share {
  height: 8.6rem;
  padding: 0 7rem 0 4rem;
  background-color: #ffffff;
  box-shadow: 0 3px 60px rgba(0, 0, 0, 0.16);
  display: flex;
  align-items: center;
  justify-content: space-between; }

.news-page__share-title {
  font-size: 2rem;
  color: #102a83;
  font-weight: 900; }

@media (max-width: 991px) {
  .news-page__share {
    padding: 0 4rem 0 3rem; } }

@media (max-width: 767px) {
  .news-page__share-title {
    margin-bottom: 3rem; }
  .news-page__share {
    height: auto;
    padding: 3rem 2rem;
    flex-direction: column;
    align-items: flex-start; }
    .news-page__share a {
      margin-bottom: 1rem; } }

.conditions {
  margin: 10rem 0 0; }
  .conditions--first {
    margin-bottom: 23rem; }
  .conditions--second {
    margin-bottom: 10rem; }

@media (max-width: 767px) {
  .conditions--first {
    margin-bottom: 15rem; } }

.contacts {
  margin: 12rem 0 8rem;
  display: grid;
  grid-template-columns: 488px 629px;
  grid-column-gap: 47px;
  justify-content: center;
  align-items: flex-start; }

.contacts__form {
  background-color: #ffffff;
  box-shadow: 0 3px 60px rgba(0, 0, 0, 0.16);
  padding: 6rem 6rem 4rem; }

.contacts__form-inner {
  margin-bottom: 4rem;
  display: grid;
  grid-template-rows: 6.4rem 6.4rem 6.4rem 9.9rem;
  grid-row-gap: 2.5rem; }

.contacts__form-title {
  margin-bottom: 4rem;
  font-size: 2.2rem;
  font-weight: 700;
  color: #102a83;
  text-align: center;
  text-transform: uppercase; }

.contacts__form-input,
.contacts__form-textarea {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-size: 1.9rem;
  font-weight: 400;
  color: #000000;
  outline: 0;
  transition: border .2s linear;
  padding: 0 1.6rem;
  background: none;
  border: 1px solid #707070;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }
  .contacts__form-input::placeholder,
  .contacts__form-textarea::placeholder {
    color: #6a6a6a; }
  .contacts__form-input:focus,
  .contacts__form-textarea:focus {
    border-color: #102a83; }

.contacts__form-textarea {
  resize: none;
  padding-top: 1rem; }

.contacts__form-btn {
  display: block;
  margin: 0 auto; }

.contacts-map {
  margin-bottom: 6rem;
  width: 100%;
  background: url("../img/map-bg.jpg") no-repeat center;
  background-size: cover; }

.contacts-map iframe {
  display: block;
  min-height: 59rem;
  border: 0;
  width: 100%; }
  @media (max-width: 575px) {
    .contacts-map iframe {
      min-height: 50rem; } }
  @media (max-width: 369px) {
    .contacts-map iframe {
      min-height: 40rem; } }

@media (max-width: 1499px) {
  .contacts__form-inner {
    grid-template-rows: 6rem 6rem 6rem 9.5rem; } }

@media (max-width: 1399px) {
  .contacts__form-title {
    margin-bottom: 3rem;
    font-size: 2.2rem; }
  .contacts__form-inner {
    grid-template-rows: 5rem 5rem 5rem 8rem; }
  .contacts__form {
    padding: 4rem 5rem 3rem; }
  .contacts__form-input,
  .contacts__form-textarea {
    font-size: 1.6rem; } }

@media (max-width: 1299px) {
  .contacts {
    padding-left: 2rem;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 3rem; } }

@media (max-width: 899px) {
  .contacts {
    padding-left: 0;
    grid-template-columns: 1fr;
    grid-row-gap: 5rem;
    justify-content: center;
    align-items: center; }
  .contacts__form {
    width: 50rem;
    margin: 0 auto; } }

@media (max-width: 575px) {
  .contacts__form {
    width: 100%; } }

@media (max-width: 499px) {
  .contacts__form-title {
    font-size: 2rem; }
  .contacts__form {
    padding: 3rem 2rem; }
  .contacts__form-inner {
    grid-template-rows: 4.5rem 4.5rem 4.5rem 7rem; } }

@media (max-width: 369px) {
  .contacts__form {
    padding: 3rem 1.5rem; } }

.social {
  padding: 3rem 0 3.5rem;
  background-color: #f7f7f7; }

.social__title {
  margin-bottom: 3rem;
  font-size: 1.9rem;
  font-weight: 400;
  color: #102a83;
  text-transform: uppercase;
  text-align: center; }

.social__list {
  display: flex;
  justify-content: center; }

.social__list-item + .social__list-item {
  margin-left: 4.5rem; }

.social__list-link,
.social__list-item {
  display: flex;
  justify-content: center;
  align-items: center; }

@media (max-width: 1299px) {
  .social {
    width: max-content;
    padding: 3rem 5rem 3.5rem; }
  .social__list-link svg {
    width: 3.5rem;
    height: 3.5rem; } }

@media (max-width: 899px) {
  .social {
    margin: 0 auto; } }

@media (max-width: 575px) {
  .social {
    margin: 0; } }

@media (max-width: 429px) {
  .social {
    width: 100%;
    padding: 3rem 2rem 3.5rem; }
  .social__list-item + .social__list-item {
    margin-left: 3rem; }
  .social__title {
    font-size: 1.9rem; }
  .social__list-link svg {
    width: 3rem;
    height: 3rem; } }

.catalog {
  margin: 17rem 0 9rem; }

.catalog__inner {
  margin-bottom: 9rem;
  display: grid;
  grid-template-columns: repeat(4, 34.6rem);
  grid-column-gap: 3.3rem;
  grid-row-gap: 3.8rem;
  justify-content: center; }

.catalog__btn {
  display: block;
  margin: 0 auto; }

@media (max-width: 1599px) {
  .catalog__inner {
    grid-template-columns: repeat(4, 1fr); } }

@media (max-width: 1299px) {
  .catalog__inner {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 3rem;
    grid-row-gap: 3.5rem; } }

@media (max-width: 991px) {
  .catalog__inner {
    grid-template-columns: repeat(2, 1fr); } }

@media (max-width: 767px) {
  .catalog {
    margin: 12rem 0 9rem; } }

@media (max-width: 575px) {
  .catalog__inner {
    grid-template-columns: 1fr; } }

/*!
 * Fotorama 4.6.4 | http://fotorama.io/license/
 */
.fotorama__arr:focus:after, .fotorama__fullscreen-icon:focus:after, .fotorama__html, .fotorama__img, .fotorama__nav__frame:focus .fotorama__dot:after, .fotorama__nav__frame:focus .fotorama__thumb:after, .fotorama__stage__frame, .fotorama__stage__shaft, .fotorama__video iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0; }

.fotorama--fullscreen, .fotorama__img {
  max-width: 99999px !important;
  max-height: 99999px !important;
  min-width: 0 !important;
  min-height: 0 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  padding: 0 !important; }

.fotorama__wrap .fotorama__grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab; }

.fotorama__grabbing * {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing; }

.fotorama__spinner {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important; }

.fotorama__wrap--css3 .fotorama__arr, .fotorama__wrap--css3 .fotorama__fullscreen-icon, .fotorama__wrap--css3 .fotorama__nav__shaft, .fotorama__wrap--css3 .fotorama__stage__shaft, .fotorama__wrap--css3 .fotorama__thumb-border, .fotorama__wrap--css3 .fotorama__video-close, .fotorama__wrap--css3 .fotorama__video-play {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.fotorama__caption, .fotorama__nav:after, .fotorama__nav:before, .fotorama__stage:after, .fotorama__stage:before, .fotorama__wrap--css3 .fotorama__html, .fotorama__wrap--css3 .fotorama__nav, .fotorama__wrap--css3 .fotorama__spinner, .fotorama__wrap--css3 .fotorama__stage, .fotorama__wrap--css3 .fotorama__stage .fotorama__img, .fotorama__wrap--css3 .fotorama__stage__frame {
  -webkit-transform: translateZ(0);
  transform: translateZ(0); }

.fotorama__arr:focus, .fotorama__fullscreen-icon:focus, .fotorama__nav__frame {
  outline: 0; }

.fotorama__arr:focus:after, .fotorama__fullscreen-icon:focus:after, .fotorama__nav__frame:focus .fotorama__dot:after, .fotorama__nav__frame:focus .fotorama__thumb:after {
  content: '';
  border-radius: inherit;
  background-color: rgba(0, 175, 234, 0.5); }

.fotorama__wrap--video .fotorama__stage, .fotorama__wrap--video .fotorama__stage__frame--video, .fotorama__wrap--video .fotorama__stage__frame--video .fotorama__html, .fotorama__wrap--video .fotorama__stage__frame--video .fotorama__img, .fotorama__wrap--video .fotorama__stage__shaft {
  -webkit-transform: none !important;
  transform: none !important; }

.fotorama__wrap--css3 .fotorama__nav__shaft, .fotorama__wrap--css3 .fotorama__stage__shaft, .fotorama__wrap--css3 .fotorama__thumb-border {
  transition-property: -webkit-transform,width;
  transition-property: transform,width;
  transition-timing-function: cubic-bezier(0.1, 0, 0.25, 1);
  transition-duration: 0ms; }

.fotorama__arr, .fotorama__fullscreen-icon, .fotorama__no-select, .fotorama__video-close, .fotorama__video-play, .fotorama__wrap {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.fotorama__select {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text; }

.fotorama__nav, .fotorama__nav__frame {
  margin: auto;
  padding: 0; }

.fotorama__caption__wrap, .fotorama__nav__frame, .fotorama__nav__shaft {
  -moz-box-orient: vertical;
  display: inline-block;
  vertical-align: middle;
  *display: inline;
  *zoom: 1; }

.fotorama__nav__frame, .fotorama__thumb-border {
  box-sizing: content-box; }

.fotorama__caption__wrap {
  box-sizing: border-box; }

.fotorama--hidden, .fotorama__load {
  position: absolute;
  left: -99999px;
  top: -99999px;
  z-index: -1; }

.fotorama__arr, .fotorama__fullscreen-icon, .fotorama__nav, .fotorama__nav__frame, .fotorama__nav__shaft, .fotorama__stage__frame, .fotorama__stage__shaft, .fotorama__video-close, .fotorama__video-play {
  -webkit-tap-highlight-color: transparent; }

.fotorama__arr, .fotorama__fullscreen-icon, .fotorama__video-close, .fotorama__video-play {
  background: url(fotorama.png) no-repeat; }

@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 2dppx) {
  .fotorama__arr, .fotorama__fullscreen-icon, .fotorama__video-close, .fotorama__video-play {
    background: url(fotorama@2x.png) 0 0/96px 160px no-repeat; } }

.fotorama__thumb {
  background-color: #7f7f7f;
  background-color: rgba(127, 127, 127, 0.2); }

@media print {
  .fotorama__arr, .fotorama__fullscreen-icon, .fotorama__thumb-border, .fotorama__video-close, .fotorama__video-play {
    background: none !important; } }

.fotorama {
  min-width: 1px;
  overflow: hidden; }

.fotorama:not(.fotorama--unobtrusive) > *:not(:first-child) {
  display: none; }

.fullscreen {
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  background: #000; }

.fotorama--fullscreen {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  float: none !important;
  z-index: 2147483647 !important;
  background: #000;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important; }

.fotorama--fullscreen .fotorama__nav, .fotorama--fullscreen .fotorama__stage {
  background: #000; }

.fotorama__wrap {
  -webkit-text-size-adjust: 100%;
  position: relative;
  direction: ltr;
  z-index: 0; }

.fotorama__wrap--rtl .fotorama__stage__frame {
  direction: rtl; }

.fotorama__nav, .fotorama__stage {
  overflow: hidden;
  position: relative;
  max-width: 100%; }

.fotorama__wrap--pan-y {
  -ms-touch-action: pan-y; }

.fotorama__wrap .fotorama__pointer {
  cursor: pointer; }

.fotorama__wrap--slide .fotorama__stage__frame {
  opacity: 1 !important; }

.fotorama__stage__frame {
  overflow: hidden; }

.fotorama__stage__frame.fotorama__active {
  z-index: 8; }

.fotorama__wrap--fade .fotorama__stage__frame {
  display: none; }

.fotorama__wrap--fade .fotorama__fade-front, .fotorama__wrap--fade .fotorama__fade-rear, .fotorama__wrap--fade .fotorama__stage__frame.fotorama__active {
  display: block;
  left: 0;
  top: 0; }

.fotorama__wrap--fade .fotorama__fade-front {
  z-index: 8; }

.fotorama__wrap--fade .fotorama__fade-rear {
  z-index: 7; }

.fotorama__wrap--fade .fotorama__fade-rear.fotorama__active {
  z-index: 9; }

.fotorama__wrap--fade .fotorama__stage .fotorama__shadow {
  display: none; }

.fotorama__img {
  -ms-filter: "alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
  border: none !important; }

.fotorama__error .fotorama__img, .fotorama__loaded .fotorama__img {
  -ms-filter: "alpha(Opacity=100)";
  filter: alpha(opacity=100);
  opacity: 1; }

.fotorama--fullscreen .fotorama__loaded--full .fotorama__img, .fotorama__img--full {
  display: none; }

.fotorama--fullscreen .fotorama__loaded--full .fotorama__img--full {
  display: block; }

.fotorama__wrap--only-active .fotorama__nav, .fotorama__wrap--only-active .fotorama__stage {
  max-width: 99999px !important; }

.fotorama__wrap--only-active .fotorama__stage__frame {
  visibility: hidden; }

.fotorama__wrap--only-active .fotorama__stage__frame.fotorama__active {
  visibility: visible; }

.fotorama__nav {
  font-size: 0;
  line-height: 0;
  text-align: center;
  display: none;
  white-space: nowrap;
  z-index: 5; }

.fotorama__nav__shaft {
  position: relative;
  left: 0;
  top: 0;
  text-align: left; }

.fotorama__nav__frame {
  position: relative;
  cursor: pointer; }

.fotorama__nav--dots {
  display: block; }

.fotorama__nav--dots .fotorama__nav__frame {
  width: 18px;
  height: 30px; }

.fotorama__nav--dots .fotorama__nav__frame--thumb, .fotorama__nav--dots .fotorama__thumb-border {
  display: none; }

.fotorama__nav--thumbs {
  display: block; }

.fotorama__nav--thumbs .fotorama__nav__frame {
  padding-left: 0 !important; }

.fotorama__nav--thumbs .fotorama__nav__frame:last-child {
  padding-right: 0 !important; }

.fotorama__nav--thumbs .fotorama__nav__frame--dot {
  display: none; }

.fotorama__dot {
  display: block;
  width: 4px;
  height: 4px;
  position: relative;
  top: 12px;
  left: 6px;
  border-radius: 6px;
  border: 1px solid #7f7f7f; }

.fotorama__nav__frame:focus .fotorama__dot:after {
  padding: 1px;
  top: -1px;
  left: -1px; }

.fotorama__nav__frame.fotorama__active .fotorama__dot {
  width: 0;
  height: 0;
  border-width: 3px; }

.fotorama__nav__frame.fotorama__active .fotorama__dot:after {
  padding: 3px;
  top: -3px;
  left: -3px; }

.fotorama__thumb {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%; }

.fotorama__nav__frame:focus .fotorama__thumb {
  z-index: 2; }

.fotorama__thumb-border {
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  border-style: solid;
  border-color: #00afea;
  background-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0.25), rgba(64, 64, 64, 0.1)); }

.fotorama__caption {
  position: absolute;
  z-index: 12;
  bottom: 0;
  left: 0;
  right: 0;
  font-family: 'Helvetica Neue',Arial,sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: #000; }

.fotorama__caption a {
  text-decoration: none;
  color: #000;
  border-bottom: 1px solid;
  border-color: rgba(0, 0, 0, 0.5); }

.fotorama__caption a:hover {
  color: #333;
  border-color: rgba(51, 51, 51, 0.5); }

.fotorama__wrap--rtl .fotorama__caption {
  left: auto;
  right: 0; }

.fotorama__wrap--no-captions .fotorama__caption, .fotorama__wrap--video .fotorama__caption {
  display: none; }

.fotorama__caption__wrap {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 5px 10px; }

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.fotorama__wrap--css3 .fotorama__spinner {
  -webkit-animation: spinner 24s infinite linear;
  animation: spinner 24s infinite linear; }

.fotorama__wrap--css3 .fotorama__html, .fotorama__wrap--css3 .fotorama__stage .fotorama__img {
  transition-property: opacity;
  transition-timing-function: linear;
  transition-duration: .3s; }

.fotorama__wrap--video .fotorama__stage__frame--video .fotorama__html, .fotorama__wrap--video .fotorama__stage__frame--video .fotorama__img {
  -ms-filter: "alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0; }

.fotorama__select {
  cursor: auto; }

.fotorama__video {
  top: 32px;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  z-index: 10; }

@-moz-document url-prefix() {
  .fotorama__active {
    box-shadow: 0 0 0 transparent; } }

.fotorama__arr, .fotorama__fullscreen-icon, .fotorama__video-close, .fotorama__video-play {
  position: absolute;
  z-index: 11;
  cursor: pointer; }

.fotorama__arr {
  position: absolute;
  width: 32px;
  height: 32px;
  top: 50%;
  margin-top: -16px; }

.fotorama__arr--prev {
  left: 2px;
  background-position: 0 0; }

.fotorama__arr--next {
  right: 2px;
  background-position: -32px 0; }

.fotorama__arr--disabled {
  pointer-events: none;
  cursor: default;
  *display: none;
  opacity: .1; }

.fotorama__fullscreen-icon {
  width: 32px;
  height: 32px;
  top: 2px;
  right: 2px;
  background-position: 0 -32px;
  z-index: 20; }

.fotorama__arr:focus, .fotorama__fullscreen-icon:focus {
  border-radius: 50%; }

.fotorama--fullscreen .fotorama__fullscreen-icon {
  background-position: -32px -32px; }

.fotorama__video-play {
  width: 96px;
  height: 96px;
  left: 50%;
  top: 50%;
  margin-left: -48px;
  margin-top: -48px;
  background-position: 0 -64px;
  opacity: 0; }

.fotorama__wrap--css2 .fotorama__video-play, .fotorama__wrap--video .fotorama__stage .fotorama__video-play {
  display: none; }

.fotorama__error .fotorama__video-play, .fotorama__loaded .fotorama__video-play, .fotorama__nav__frame .fotorama__video-play {
  opacity: 1;
  display: block; }

.fotorama__nav__frame .fotorama__video-play {
  width: 32px;
  height: 32px;
  margin-left: -16px;
  margin-top: -16px;
  background-position: -64px -32px; }

.fotorama__video-close {
  width: 32px;
  height: 32px;
  top: 0;
  right: 0;
  background-position: -64px 0;
  z-index: 20;
  opacity: 0; }

.fotorama__wrap--css2 .fotorama__video-close {
  display: none; }

.fotorama__wrap--css3 .fotorama__video-close {
  -webkit-transform: translate3d(32px, -32px, 0);
  transform: translate3d(32px, -32px, 0); }

.fotorama__wrap--video .fotorama__video-close {
  display: block;
  opacity: 1; }

.fotorama__wrap--css3.fotorama__wrap--video .fotorama__video-close {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__arr, .fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon {
  opacity: 0; }

.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__arr:focus, .fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon:focus {
  opacity: 1; }

.fotorama__wrap--video .fotorama__arr, .fotorama__wrap--video .fotorama__fullscreen-icon {
  opacity: 0 !important; }

.fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__arr, .fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon {
  display: none; }

.fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__arr:focus, .fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon:focus {
  display: block; }

.fotorama__wrap--css2.fotorama__wrap--video .fotorama__arr, .fotorama__wrap--css2.fotorama__wrap--video .fotorama__fullscreen-icon {
  display: none !important; }

.fotorama__wrap--css3.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon:not(:focus) {
  -webkit-transform: translate3d(32px, -32px, 0);
  transform: translate3d(32px, -32px, 0); }

.fotorama__wrap--css3.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows .fotorama__arr--prev:not(:focus) {
  -webkit-transform: translate3d(-48px, 0, 0);
  transform: translate3d(-48px, 0, 0); }

.fotorama__wrap--css3.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows .fotorama__arr--next:not(:focus) {
  -webkit-transform: translate3d(48px, 0, 0);
  transform: translate3d(48px, 0, 0); }

.fotorama__wrap--css3.fotorama__wrap--video .fotorama__fullscreen-icon {
  -webkit-transform: translate3d(32px, -32px, 0) !important;
  transform: translate3d(32px, -32px, 0) !important; }

.fotorama__wrap--css3.fotorama__wrap--video .fotorama__arr--prev {
  -webkit-transform: translate3d(-48px, 0, 0) !important;
  transform: translate3d(-48px, 0, 0) !important; }

.fotorama__wrap--css3.fotorama__wrap--video .fotorama__arr--next {
  -webkit-transform: translate3d(48px, 0, 0) !important;
  transform: translate3d(48px, 0, 0) !important; }

.fotorama__wrap--css3 .fotorama__arr:not(:focus), .fotorama__wrap--css3 .fotorama__fullscreen-icon:not(:focus), .fotorama__wrap--css3 .fotorama__video-close:not(:focus), .fotorama__wrap--css3 .fotorama__video-play:not(:focus) {
  transition-property: -webkit-transform,opacity;
  transition-property: transform,opacity;
  transition-duration: .3s; }

.fotorama__nav:after, .fotorama__nav:before, .fotorama__stage:after, .fotorama__stage:before {
  content: "";
  display: block;
  position: absolute;
  text-decoration: none;
  top: 0;
  bottom: 0;
  width: 10px;
  height: auto;
  z-index: 10;
  pointer-events: none;
  background-repeat: no-repeat;
  background-size: 1px 100%,5px 100%; }

.fotorama__nav:before, .fotorama__stage:before {
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.3) 75%, transparent), radial-gradient(farthest-side at 0 50%, rgba(0, 0, 0, 0.4), transparent);
  background-position: 0 0,0 0;
  left: -10px; }

.fotorama__nav.fotorama__shadows--left:before, .fotorama__stage.fotorama__shadows--left:before {
  left: 0; }

.fotorama__nav:after, .fotorama__stage:after {
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.3) 75%, transparent), radial-gradient(farthest-side at 100% 50%, rgba(0, 0, 0, 0.4), transparent);
  background-position: 100% 0,100% 0;
  right: -10px; }

.fotorama__nav.fotorama__shadows--right:after, .fotorama__stage.fotorama__shadows--right:after {
  right: 0; }

.fotorama--fullscreen .fotorama__nav:after, .fotorama--fullscreen .fotorama__nav:before, .fotorama--fullscreen .fotorama__stage:after, .fotorama--fullscreen .fotorama__stage:before, .fotorama__wrap--fade .fotorama__stage:after, .fotorama__wrap--fade .fotorama__stage:before, .fotorama__wrap--no-shadows .fotorama__nav:after, .fotorama__wrap--no-shadows .fotorama__nav:before, .fotorama__wrap--no-shadows .fotorama__stage:after, .fotorama__wrap--no-shadows .fotorama__stage:before {
  display: none; }

.product {
  margin-bottom: 9rem;
  padding-left: 7rem;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 10rem;
  align-items: flex-start; }

.product__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start; }

.product__title {
  margin-bottom: 2rem;
  font-size: 3rem;
  font-weight: 700;
  color: #102a83;
  padding: 0 0 .5rem 0;
  position: relative; }
  .product__title:after {
    content: '';
    width: 50%;
    height: 6px;
    background-color: #bf334d;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1; }

.product__price {
  margin-bottom: 3rem;
  background-color: #102a83;
  color: #ffffff;
  font-size: 2.5rem;
  font-weight: 700;
  padding: 1rem 2rem; }

.product__long {
  font-size: 2rem; }
  .product__long span {
    font-weight: 700;
    margin-left: .5rem; }

.product__existence {
  margin-bottom: 2.5rem;
  font-size: 2rem; }
  .product__existence span {
    font-weight: 700;
    color: #39ba89;
    margin-left: .5rem; }

.product__price-per-quantity {
  margin-bottom: 3.5rem;
  font-size: 1.9rem;
  font-weight: 900;
  padding: 0 0 .2rem 0;
  position: relative; }
  .product__price-per-quantity:after {
    content: '';
    width: 50%;
    height: 2px;
    background-color: #bf334d;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1; }

.product__standard {
  margin-bottom: 4.5rem;
  font-size: 1.9rem;
  font-weight: 900; }

.product__size {
  margin-bottom: 6rem;
  display: flex; }

.product__size-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5rem;
  height: 5rem;
  background-color: #102a83;
  cursor: pointer;
  color: #ffffff;
  font-weight: 700;
  transition: background-color .2s linear; }
  .product__size-label:hover {
    background-color: #0d236c; }
  .product__size-label.active {
    background-color: #eaeaea;
    color: #102a83; }

.product__size-label + .product__size-label {
  margin-left: 1rem; }

.product__action {
  display: flex; }

.product__counter {
  margin-right: 3rem;
  display: flex;
  align-items: center; }

.product__counter-minus,
.product__counter-plus {
  width: 3rem;
  height: 6rem;
  background-color: #102a83;
  padding: 0;
  border: 0;
  cursor: pointer;
  position: relative;
  transition: background-color .2s linear; }
  .product__counter-minus:hover,
  .product__counter-plus:hover {
    background-color: #0d236c; }
  .product__counter-minus:before, .product__counter-minus:after,
  .product__counter-plus:before,
  .product__counter-plus:after {
    content: '';
    display: block;
    background-color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.product__counter-plus:before,
.product__counter-minus:before {
  width: 10px;
  height: 2px; }

.product__counter-plus:after {
  width: 2px;
  height: 10px; }

.product__counter-input {
  padding: 0 1rem;
  margin: 0 1rem;
  width: 7.5rem;
  height: 6rem;
  border: 1px solid #707070;
  color: #707070;
  font-size: 2rem;
  outline: none; }

.product__action-buttons {
  display: flex;
  align-items: center; }

.product__btn-buy {
  height: 6rem;
  margin-right: 3rem;
  background-color: #102a83;
  padding: 0 5rem;
  display: flex;
  align-items: center;
  font-size: 2rem;
  font-weight: 400;
  color: #ffffff;
  transition: background-color .2s linear; }
  .product__btn-buy:hover {
    background-color: #0d236c; }
  .product__btn-buy svg {
    display: block;
    margin-left: .5rem;
    margin-top: .2rem; }

.product__btn-question {
  height: 6rem;
  padding: 0 1.5rem;
  background-color: #ffffff;
  border: 2px solid #102a83;
  font-size: 2rem;
  font-weight: 400;
  color: #102a83; }

@media (max-width: 1599px) {
  .product {
    padding-left: 3rem;
    grid-column-gap: 5rem; } }

@media (max-width: 1399px) {
  .product {
    grid-column-gap: 3rem; }
  .product__title {
    font-size: 2.5rem; }
  .product__price {
    font-size: 2rem; }
  .product__action {
    display: block; }
  .product__counter {
    margin-bottom: 3rem;
    margin-right: 0; } }

@media (max-width: 1299px) {
  .product__long,
  .product__existence {
    font-size: 1.9rem; }
  .product__price-per-quantity,
  .product__standard {
    font-size: 1.8rem; }
  .product__title:after {
    height: 3px; }
  .product {
    grid-template-columns: 1fr 1fr; } }

@media (max-width: 1199px) {
  .product {
    padding-left: 0; } }

@media (max-width: 991px) {
  .product {
    grid-template-columns: 1fr;
    grid-row-gap: 3rem;
    padding-left: 2rem; }
  .product__content {
    padding-left: 2rem; } }

@media (max-width: 767px) {
  .product {
    padding-left: 0; }
  .product__content {
    padding-left: 3rem; }
  .product__price {
    font-size: 1.8rem; }
  .product__price-per-quantity,
  .product__standard {
    font-size: 1.6rem; }
  .product__size-label {
    font-size: 1.5rem; }
  .product__counter-minus,
  .product__counter-plus {
    height: 5rem; }
  .product__counter-input {
    width: 6rem;
    height: 5rem;
    font-size: 1.8rem; }
  .product__size {
    margin-bottom: 4rem; }
  .product__btn-buy, .product__btn-question {
    height: 5rem;
    font-size: 1.8rem; }
  .product__btn-buy svg {
    width: 1.5rem;
    height: 1.5rem; } }

@media (max-width: 575px) {
  .product__content {
    padding-left: 0; }
  .product__title {
    font-size: 2.2rem; }
  .product__size-label {
    font-size: 1.4rem; } }

@media (max-width: 399px) {
  .product__action-buttons {
    display: block; }
  .product__btn-buy {
    margin-right: 0;
    margin-bottom: 2rem;
    padding: 0 4rem; }
  .product__btn-question {
    padding: 0 1.85rem; } }

@media (max-width: 349px) {
  .product__size-label + .product__size-label {
    margin-left: .9rem; } }

.tabs {
  margin-bottom: 13rem; }

.tabs__triggers {
  display: flex; }

.tabs__triggers-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 5rem 2rem;
  text-align: center;
  font-size: 1.9rem;
  font-weight: 400;
  color: #102a83;
  background-color: #ffffff;
  border: 2px solid #102a83;
  border-bottom: none; }

.tabs__triggers-item + .tabs__triggers-item {
  margin-left: 1rem; }

.tabs__triggers-item--active {
  background-color: #102a83;
  color: #ffffff; }

.tabs__content-item {
  display: none;
  padding: 6rem 3.5rem 5.5rem;
  background-color: #f8faff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
  .tabs__content-item p {
    font-size: 1.9rem;
    margin-bottom: 2rem; }
    .tabs__content-item p:last-of-type {
      margin-bottom: 0; }

.tabs__content-title {
  margin-bottom: 4rem;
  font-size: 3rem;
  font-weight: 700; }

.tabs__content-item--active {
  display: block; }

@media (max-width: 1199px) {
  .tabs__content-title {
    margin-bottom: 3rem;
    font-size: 2.8rem; }
  .tabs__content-item {
    padding: 5rem 3rem; }
    .tabs__content-item p {
      font-size: 1.8rem; } }

@media (max-width: 767px) {
  .tabs__content-title {
    font-size: 2.5rem; }
  .tabs__content-item {
    padding: 3rem; }
    .tabs__content-item p {
      font-size: 1.7rem; }
  .tabs__triggers-item {
    padding: 1.5rem 4rem;
    font-size: 1.8rem; } }

@media (max-width: 575px) {
  .tabs__content-item {
    padding: 3rem 2rem; }
    .tabs__content-item p {
      font-size: 1.6rem; }
  .tabs__content-title {
    font-size: 2.2rem; }
  .tabs__triggers-item {
    padding: 1.4rem 3rem;
    font-size: 1.7rem; } }

@media (max-width: 439px) {
  .tabs__triggers-item + .tabs__triggers-item {
    margin-left: .5rem; }
  .tabs__triggers {
    justify-content: space-between; }
  .tabs__triggers-item {
    border-width: 1px; }
  .tabs__triggers-item {
    padding: 1.2rem .5rem 1rem;
    font-size: 1.6rem;
    width: 50%; } }

.extra {
  margin: 9rem 0 12rem;
  display: grid;
  grid-template-columns: repeat(4, 34.6rem);
  grid-column-gap: 3rem;
  justify-content: center; }

@media (max-width: 1599px) {
  .extra {
    grid-template-columns: repeat(4, 1fr); } }

@media (max-width: 1199px) {
  .extra {
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 3rem; } }

@media (max-width: 991px) {
  .extra {
    grid-template-columns: 1fr 1fr; } }

@media (max-width: 575px) {
  .extra {
    grid-template-columns: 1fr; } }

.order__container {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto; }

.order__rectangles {
  margin-bottom: 3.5rem;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-row-gap: 1.5rem; }

.order__rectangle {
  background-color: #f8faff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  display: flex;
  justify-content: space-between;
  padding: 0 4rem 0 0; }

.order__rectangle-col {
  display: flex;
  align-items: center; }

.order__rectangle-img {
  margin-right: 6rem; }

.order__rectangle-title {
  display: block;
  font-size: 1.6rem;
  font-weight: 700;
  color: #102a83; }

.order__rectangle-price {
  margin-right: 16rem;
  font-size: 1.6rem;
  font-weight: 700;
  color: #102a83; }

.order__rectangle-amount {
  margin-right: 17rem;
  font-size: 1.6rem;
  font-weight: 700;
  color: #102a83; }

.order__rectangle-remove {
  width: 2.5rem;
  height: 2.2rem;
  background-color: #102a83;
  padding: 0;
  position: relative;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  transition: transform .2s linear; }

.order__rectangle-remove:after,
.order__rectangle-remove:before {
  content: "";
  display: block;
  width: 14px;
  height: 4px;
  background-color: #ffffff;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -2px;
  margin-left: -7px; }

.order__rectangle-remove:after {
  transform: rotate(-45deg); }

.order__rectangle-remove:before {
  transform: rotate(45deg); }

.order__result {
  margin-bottom: 4rem;
  height: 11rem;
  background-color: #f8faff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  padding: 0 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .order__result.last {
    margin-bottom: 0; }

.order__result-text {
  font-size: 1.9rem; }
  .order__result-text span {
    margin-left: 2.5rem;
    color: #102a83;
    font-weight: 800; }

.order__result-btn {
  font-size: 2rem; }

.order__squares {
  margin-bottom: 2.2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 3rem; }

.order__square {
  padding-top: 2.5rem;
  height: 457px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background-color: #f8faff; }

.order__square-inner {
  padding: 0 4rem 0 2rem;
  display: grid;
  grid-template-rows: 5rem 5rem 5rem 10rem;
  grid-row-gap: 2rem; }

.order__square-title {
  padding-left: 3rem;
  margin-bottom: 3.5rem;
  font-size: 1.9rem;
  font-weight: 700; }

.order__square-input,
.order__square-textarea {
  margin-left: 2rem;
  font-family: 'Roboto',sans-serif;
  font-style: normal;
  font-size: 1.9rem;
  font-weight: 400;
  color: #6a6a6a;
  padding: 0 2rem;
  background-color: #ffffff;
  border: 1px solid #707070;
  outline: 0;
  transition: border .2s linear; }
  .order__square-input:focus,
  .order__square-textarea:focus {
    border-color: #102a83; }

.order__square-input {
  width: 32rem;
  height: 5rem; }

.order__square-textarea {
  padding-top: 16px;
  display: block;
  width: 32rem;
  height: 10rem;
  resize: none; }

.order__square-label {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 1.9rem;
  color: #6a6a6a;
  cursor: pointer; }

.order__big {
  margin-bottom: 4rem;
  padding-top: 2.5rem;
  height: 457px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background-color: #f8faff; }

.order__big-title {
  padding-left: 3rem;
  margin-bottom: 3.5rem;
  font-size: 1.9rem;
  font-weight: 700; }

.order__big-inner {
  padding-right: 4rem; }

.order__big-label {
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 1.9rem;
  color: #6a6a6a;
  cursor: pointer; }

.order__big-label + .order__big-label {
  margin-top: 3rem; }

.order__big-input {
  width: 75%;
  height: 5rem;
  margin-left: 2rem;
  font-family: 'Roboto',sans-serif;
  font-style: normal;
  font-size: 1.9rem;
  font-weight: 400;
  color: #6a6a6a;
  padding: 0 2rem;
  background-color: #ffffff;
  border: 1px solid #707070;
  outline: 0;
  transition: border .2s linear; }
  .order__big-input:focus {
    border-color: #102a83; }

.order__big-label__street {
  margin-right: 2rem; }

.order__big-many {
  display: flex;
  justify-content: flex-start;
  width: 75%; }

.order__big-input.has-small {
  width: 50%;
  margin: 0; }

.order__big-input.small {
  width: 9rem;
  margin: 0 0 0 1.5rem; }

.order__choose-wrap {
  padding-left: 3rem;
  display: flex; }

.order__square-choose {
  margin-right: 2rem;
  font-size: 1.9rem;
  color: #6a6a6a; }

.order__radios {
  display: flex;
  flex-direction: column; }
  .order__radios input[type=radio] {
    display: none; }
  .order__radios label {
    margin-bottom: 2.5rem;
    cursor: pointer;
    font-size: 1.9rem;
    user-select: none; }
    .order__radios label:last-of-type {
      margin-bottom: 0; }
    .order__radios label span {
      display: block;
      padding-left: 3.2rem;
      font-size: 1.2rem;
      color: #000000; }
  .order__radios label:before {
    content: '';
    display: inline-block;
    width: 18px;
    height: 18px;
    background-color: #ffffff;
    border: 1px solid #707070;
    border-radius: 50%;
    flex-shrink: 0;
    flex-grow: 0;
    margin-bottom: -2px;
    margin-right: 14px;
    background-repeat: no-repeat;
    background-position: center center; }
  .order__radios input[type=radio]:checked + label:before {
    background-image: url("../img/radio-checked.png"); }

@media (max-width: 1299px) {
  .order__rectangle-price {
    margin-right: 8rem; }
  .order__rectangle-amount {
    margin-right: 8rem; } }

@media (max-width: 1199px) {
  .order__choose-wrap {
    flex-direction: column; }
  .order__square-choose {
    margin: 0 0 2.5rem 0; } }

@media (max-width: 991px) {
  .order__rectangle {
    display: block;
    padding: 0 0 3rem 0; }
  .order__rectangle-col:first-of-type {
    margin-bottom: 2.5rem;
    padding-right: 2rem; }
  .order__rectangle-col:last-of-type {
    padding-left: 2rem; }
  .order__rectangle-img {
    margin-right: 3rem; }
  .order__result-btn {
    font-size: 1.9rem;
    padding: 1.2rem 3.5rem; }
  .order__squares {
    grid-template-columns: 57rem;
    grid-row-gap: 3rem;
    justify-content: center; }
  .order__choose-wrap {
    flex-direction: row; }
  .order__square-choose {
    margin: 0 2rem 0 0; }
  .order__square-label,
  .order__big-label,
  .order__radios label,
  .order__square-choose {
    font-size: 1.8rem; }
  .order__square-textarea,
  .order__square-input,
  .order__big-input {
    font-size: 1.6rem; } }

@media (max-width: 689px) {
  .order__squares {
    grid-template-columns: 1fr; }
  .order__square,
  .order__big {
    height: auto;
    padding: 3rem 0 5rem; }
  .order__square-inner {
    display: flex;
    flex-direction: column;
    padding: 0 5rem 0 3rem; }
  .order__square-input {
    height: 4rem; }
  .order__square-textarea {
    height: 7rem; }
  .order__big-input {
    height: 4rem; }
  .order__big-input.small {
    width: 6.5rem;
    text-align: center;
    padding: 0 1rem;
    margin: 0 0 0 1rem; } }

@media (max-width: 629px) {
  .order__result {
    display: block;
    height: auto;
    padding: 3rem 2rem; }
  .order__result-text {
    margin-bottom: 3rem; }
  .order__result-btn {
    font-size: 1.8rem; } }

@media (max-width: 575px) {
  .order__rectangle-col:last-of-type {
    padding: 0;
    justify-content: space-around; }
  .order__rectangle-amount,
  .order__rectangle-price {
    margin: 0; }
  .order__result-btn {
    font-size: 1.7rem;
    padding: 1.1rem 3rem; }
  .order__result-text {
    font-size: 1.8rem; }
  .order__square-inner,
  .order__big-inner {
    padding: 0 3rem; }
  .order__square-label,
  .order__big-label {
    flex-direction: column;
    align-items: flex-start;
    text-align: left; }
  .order__square-input,
  .order__square-textarea,
  .order__big-input {
    margin: 1rem 0 0;
    width: 100%; }
  .order__big-many {
    justify-content: center;
    flex-wrap: wrap;
    width: 100%; }
  .order__big-input.has-small {
    width: 100%;
    margin: 1rem 0 0; }
  .order__big-input.small {
    width: 45%;
    margin: 1rem .5rem 0; }
  .order__choose-wrap {
    flex-direction: column;
    padding: 0 3rem; }
  .order__square-choose {
    margin: 0 0 2.5rem 0; } }

@media (max-width: 489px) {
  .order__rectangle-col:first-of-type {
    padding: 0;
    display: block; }
  .order__rectangle-img {
    margin: 0 0 1.5rem 0; }
  .order__rectangle-title {
    padding: 0 1rem; } }

@media (max-width: 429px) {
  .order__result-text span {
    margin: 0 0 0 2rem; }
  .order__square-inner,
  .order__big-inner,
  .order__choose-wrap {
    padding: 0 2rem; } }

.thanks__container {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto; }

.thanks__title {
  margin-bottom: 4rem;
  font-size: 3.2rem;
  color: #102a83;
  font-weight: 700;
  text-align: center; }

.thanks__text {
  margin-bottom: 4.5rem;
  text-align: center;
  font-size: 1.9rem;
  color: #102a83;
  font-weight: 400; }
